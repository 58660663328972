import GameObject from '../../abstractions/GameObject'
import Files from '../../../managers/assetsManager/Files'
import { Vector3 } from 'three';
import { gameConfig } from '../../config/gameConfig';
import { Box3 } from 'three';

export default class Corona extends GameObject {

  init({ position, rotation, scale } = props) {
    const gameAssets = Files.get('game')
    this.name = "corona"
    this.mesh = gameAssets['corona'].scene.children[0].clone()
    const material = this.mesh.material
    const colorParsChunk = [
      'vec3 displace(vec3 v) {',
      'float dist = distance(v.z, cameraPosition.z);',
      'if( dist < 0.0) { dist = 0.0; }',
      'float addY = dist * dist;',
      `return vec3(v.x, v.y - addY * (1. * ${gameConfig.bendY.toString()}), v.z);`,
      '}',
      '#include <common>'
    ].join('\n');

    const instanceColorChunk = [
      '#include <worldpos_vertex>',
      'transformed = worldPosition.xyz;',
      'transformed = displace(transformed);',
      'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
    ].join('\n');


    material.onBeforeCompile = function (shader) {

      shader.vertexShader = shader.vertexShader
        .replace('#include <common>', colorParsChunk)
        .replace('#include <worldpos_vertex>', instanceColorChunk);
    };

    this.position = position || new Vector3();
    this.scale = scale || new Vector3(.15, .15, .15);
    this.rotation = rotation || new Vector3();

    this.base = this.mesh
    this.base.scale.set(this.scale.x, this.scale.y, this.scale.z)
    this.base.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
    this.base.position.set(this.position.x, this.position.y, this.position.z)

    this.isCollide = false
    this.base.castShadow = true
    // this.base.receiveShadow = true
    this.addBody()
  }

  addBody() {
    this.body = new Box3(new Vector3(), new Vector3())
    this.body.name = "corona"
    this.body.collision = false
  }

  destroy() {
    this.base.geometry.dispose()
    super.destroy()
  }

  checkCollistion(box) {
    const collision = this.body.intersectsBox(box)
    return collision
  }

  sceneUpdate() {
    this.base.position.z -= gameConfig.coinSpeed * gameConfig.speed
  }


  update(dt) {
    super.update(dt)
    this.base.rotation.y += .03
    this.body.copy(this.mesh.geometry.boundingBox).applyMatrix4(this.mesh.matrixWorld);
  }
}

