import AppStore from "../../store/AppStore"

const pressedKeys = {}

function init() {
	addEventListener( 'keyup', onKeyUp )
	addEventListener( 'keydown', onKeyDown )
}

function onKeyDown() {
	pressedKeys[ event.code ] = true
	
	// console.log('KEYDOWN',{	type: 'keyDown',
	// originalEvent: event,
	// key: event.key,
	// code: event.code})

	AppStore.keyDown.dispatch( {

		type: 'keyDown',
		originalEvent: event,
		key: event.key,
		code: event.code

	} )
}

function onKeyUp() {
	delete pressedKeys[ event.code ]

	AppStore.keyUp.dispatch( {

		type: 'keyUp',
		originalEvent: event,
		key: event.key,
		code: event.code

	} )
}

export default { init }
