import { Object3D } from 'three'

import BaseComponent from './BaseComponent'

let count = 0

export function addGameObject() {
	return count++
}

export function removeGameObject() {
	return count--
}

export function getCount() {
	return count
}

export default class GameObject extends BaseComponent {

	constructor( props = {} ) {
		addGameObject()
		super()
		if ( ! props.noInit ) this.init( props )
		if ( ! this.props ) this.props = props
		if ( ! this.base ) this.base = new Object3D()
		if ( this.static === undefined ) this.static = false
	}

	destroy() {
		removeGameObject()
		super.destroy()
	}

}
