<template>
  <div class="popin u-wrapper-panel u-row u-center u-middle">
    <div class="u-w5of12 u-w10of12--sm u-relative">
      <div class="popin__wrapper u-relative u-radius u-overflow-hidden">
        <div class="popin__content popin__fader u-relative">
          <div class="t-h2 u-blue u-relative">
            {{ title }}
          </div>
          <img :src="image" class="u-marg-t-md u-block u-full-width">
          <app-button @click="$emit('onClick')" v-if="cta !== ''" class="u-marg-t-md">
            {{ cta }}
          </app-button>
        </div>
      </div>
      <div class="popin__picto popin__fader u-round u-box-1by1" :class="'u-bg-' + pictoColor">
        <div class="popin__picto__wrapper u-wrapper-panel">
          <svg class="u-fill-white u-full-width u-full-height" preserveAspectRatio="xMidYMid meet">
            <use :xlink:href="'#' + svg"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    svg: {
      type: String,
      default: 'interrogation'
    },
    pictoColor: {
      type: String,
      default: 'blue'
    },
    cta: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'
@import '~@/styles/settings/mixins/mq'

.popin
  &__wrapper
    padding: 80px 30px 30px

    &:before
      position absolute
      content ''
      top 0
      left 0 
      width 100%
      height 100%
      background-color: $colors.white
      transform scale(1, 1)
      transition transform .5s .2s ease-in-out-quart
      opacity .8
      animation .6s ease-in-out-quad 1 intro

  &__fader
    transition opacity .5s linear
    opacity 1

  &.custom-enter-active &__fader
    transition opacity .6s .3s linear
    
  &.custom-enter-from &__fader
    opacity 0

  &.custom-leave-to &__fader
    opacity 0

  &.custom-leave-to &__wrapper:before
    transform scale(0, 0)

  &__picto
    width 100px
    position absolute !important
    top -50px
    left calc(50% - 50px)

    &__wrapper
      padding 20%
    
    +mq($until: 'tablet')
      width 50px
      top -25px
      left calc(50% - 25px)

@keyframes intro
  0%
    transform scale(0, .1) translateZ(0)

  // 50%
  //   transform scale(1, .3) translateZ(0)

  100%
    transform scale(1, 1) translateZ(0)
</style>