import {

	DirectionalLight,
	Fog,
	AmbientLight,
	Vector3,
	Mesh,
	MeshBasicMaterial,
	BoxBufferGeometry,
	Quaternion,
	MeshPhongMaterial,
	MeshStandardMaterial

} from 'three'

import Scene from '../abstractions/Scene'
import DebugCamera from '../components/cameras/DebugCamera'
import DebugStore from '../../store/DebugStore'

export default class DefaultScene extends Scene {

	constructor() {
		super()

		/// #if DEVELOPMENT
		this.devTools()
		/// #endif
	}

	init() {
		this.base.fog = (new Fog(0x000000, 0, 55))
		const material = new MeshStandardMaterial({ color: 'blue' })
		this.mesh = new Mesh(new BoxBufferGeometry(1, 1), material)

		this.add(this.mesh)
		this.initLights()

		this.enter()

		this.bind()
	}

	bind() {
	}

	initLights() {
		const light = new AmbientLight(0x777777, .9)
		light.position.z = 0
		light.position.y = 1
		light.position.x = 0
		// this.add( light );
	}

	enter() {
		super.enter()
	}

	destroy() {
		// console.log('default destroyeds')
		/// #if DEVELOPMENT
		this.removeComponent(this.debugCamera)
		DebugStore.debugCamera.unsubscribe(this.toggleOrbitControl, this)
		this.gridHelp = null
		this.debugCamera = null
		/// #endif

		this.mesh.geometry.dispose()
		this.mesh.material.dispose()
		super.destroy()
	}

	update() {
		super.update()
	}

	/// #if DEVELOPMENT
	devTools() {
		this.debugCamera = this.addComponent(DebugCamera.get())
		DebugStore.debugCamera.subscribe(this.toggleOrbitControl, this)
		this.toggleOrbitControl(DebugStore.debugCamera.current)
	}

	toggleDebug(v) {
		this.gridHelp.visible = v
	}

	toggleOrbitControl(v) {
		this.debugCamera.base.position.copy(new Vector3(0, 0, 5))
		this.debugCamera.base.quaternion.copy(new Quaternion())

		if (v) this.useCamera(this.debugCamera)
		else this.previousCamera && this.useCamera(this.previousCamera)
	}
	/// #endif

}
