<template>
  <app-overlay @close="$emit('close')">
    <a :href="datum.url" target="_blank" v-for="datum in data" class="u-block u-row u-full-width u-middle u-marg-b-sm u-marg-t-md u-cursor-pointer" @click="onLink(datum.slug)">
      <div class="u-w2of12">
        <div class="menu__round u-round" :class="`u-bg-${datum.color}`">
          <svg class="u-full-width u-full-height u-fill-white" preserveAspectRatio="xMidYMid meet">
            <use :xlink:href="`#${datum.icon}`"/>
          </svg>
        </div>
      </div>
      <div class="t-h2 u-pad-l-0 u-pad-l-sm--xs u-uppercase u-w10of12" :class="`u-${datum.color}`">
        {{ datum.name }}
      </div>
    </a>
  </app-overlay>
</template>

<script>
import AppOverlay from '@/components/AppOverlay'
import { socials } from '@/assets/data'

import CopyPaste from '@/utils/CopyPaste'

export default {

  components: {
    AppOverlay
  },

  data () {
    return {
      data: socials
    }
  },

  methods: {
    onLink (slug) {
      if (slug === 'copy') {
        CopyPaste.makeCopy({values: window.location.origin})
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="stylus">
.menu
  &__round
    height 55px
    width 55px
    padding 15px
</style>