<template>
  <div class="plop" @click="isPopin = !isPopin">
    <div class="t-h1">
      t-h1
    </div>
    <div class="t-h2">
      t-h2
    </div>
    <div class="t-h3">
      t-h3
    </div>
    <div class="t-h4">
      t-h4
    </div>
    <div class="t-h5">
      t-h5
    </div>
    <div class="t-text">
      t-text
    </div>
    <div>
      <app-button>
        Primary with icon
      </app-button>
      <app-button :isGhost="true">
        Primary with icon
      </app-button>
      <app-button :isWhite="true">
        Primary with icon
      </app-button>
    </div>
    <div>
      <app-button-quizz>
        Primary with icon
      </app-button-quizz>
      <app-button-quizz :isTrue="true">
        Primary with icon
      </app-button-quizz>
    </div>
    <transition name="custom" duration="1200">
      <app-popin v-if="isPopin" subtitle="subtitle" image="/images/temp.png" />
    </transition>
    <div>
      <timer />
    </div>
  </div>
</template>

<script>
import AppButtonQuizz from '@/components/common/AppButtonQuizz'
import AppPopin from '@/components/common/AppPopin'
import Timer from '@/components/common/Timer'

export default {
  data () {
    return {
      isPopin: false
    }
  },

  components: {
    AppButtonQuizz,
    AppPopin,
    Timer
  },
}
</script>

<style lang="stylus">
.plop
  background-color #aaaaaa
  min-height 100vh
</style>