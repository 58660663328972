import { scoring } from '@/assets/data'

export default {
	namespaced: true,

	state: {
		isFirstHomepage: true,
		isFirstTuto: true
	},

	getters: {
		isFirstHomepage (state) {
			return state.isFirstHomepage
		},
		isFirstTuto (state) {
			return state.isFirstTuto
		},
	},

	mutations: {
		isFirstHomepage (state) {
			state.isFirstHomepage = false
		},
		isFirstTuto (state) {
			state.isFirstTuto = false
		}
	}
}