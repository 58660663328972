<template>
  <div class="scoring u-bg-white u-radius u-shadow" :class="{'is-big': big}">
    <div class="u-row u-blue" :class="big ? 't-h2 t-text--sm' : 't-h5'">
      <div class="scoring__img u-marg-r-sm u-marg-r-xs--sm">
        <img class="u-block u-full-width" src="/images/card.png">
      </div>
      <div class="scoring__score u-center u-pad-r-xs">{{ currentScore }}</div>Votes
      <div
        v-for="i in 2"
        class="scoring__delta u-white u-round u-row u-center u-middle"
        :class="[i === 1 ? 'u-bg-red' : 'u-bg-green', {'is-visible': (i === 1 && deltaNegVisible) || (i === 2 && deltaPosVisible)}]"
      >
        <div class="scoring__delta__txt">
          {{ i === 1 ? '-' : ''}} {{ Math.abs(i === 1 ? deltaNeg : deltaPos) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  props: {
    score: {
      type: Number,
      default: 0
    },
    big: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      currentScore: 0,
      delta: 0,
      deltaPosVisible: false,
      deltaNegVisible: false,
      deltaPos: false,
      deltaNeg: false,
      decrease: false
    }
  },

  created () {
    this.currentScore = this.score
    this.eased = {
      score: 0
    }
  },

  methods: {
    animateNumber () {
      this.gsap = gsap.to(this.eased, {
        score: this.score,
        duration: 1,
        onUpdate: () => {
          this.currentScore = Math.round(this.eased.score)
        }
      })
    }
  },

  watch: {
    score (to, from) {
      clearTimeout(this.timeout1)
      this.animateNumber()
      if (!this.deltaNegVisible && to < from) {
        this.decrease = true
        this.deltaNeg = 0
        this.deltaPosVisible = false
        this.deltaNegVisible = true
      }
      else if(!this.deltaPosVisible && to > from) {
        this.decrease = false
        this.deltaPos = 0
        this.deltaPosVisible = true
        this.deltaNegVisible = false
      }

      this.deltaNeg += from - to
      this.deltaPos += from - to

      this.timeout1 = setTimeout(() => {
        this.deltaPosVisible = false
        this.deltaNegVisible = false
        this.animateNumber()
      }, 1000)
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.scoring
  display inline-block
  padding 7px 15px
  border-radius 20px

  &.is-big
    padding 10px 15px
    border-radius 45px

    +mq($until: 'tablet')
      padding 6px 13px
  
  &__img
    width 1.7em
    height auto

  &.is-big &__score
    min-width 1.5em
  
  &__delta
    position absolute
    top calc( 50% - 38px )
    left -50px
    width 76px
    height 76px
    transform scale(0, 0)
    transition transform .3s ease-in-quad

    +mq($until: 'tablet')
      top calc(50% - 20px)
      left -25px
      width 40px
      height 40px
    
    &__txt
      transform rotate(-20deg)
    
    &.is-visible
      transition transform .5s custom-bounce
      transform scale(1, 1)

    
</style>