<template>
  <app-overlay @close="$emit('close')">
    <div class="u-row u-top u-full-width">
      <div class="u-black u-w8of12 u-w12of12--sm">
        <div class="t-h1 u-marg-b-sm">Qu’est-ce que «Run le Programme» ?</div>

        <p class="t-text u-marg-b-md">"Run le Programme" est un jeu-vidéo visant à faire découvrir de façon ludique les programmes des candidats à l’élection présidentielle de 2022.</p>

        <p class="t-text u-marg-b-md">L’objectif pour le joueur, consiste à indiquer le plus rapidement possible si telle ou telle proposition appartient bien au candidat avec lequel il joue. Ce quizz chronométré est mixé avec le genre du «runner», c’est à dire une course où l’on doit accumuler des pointstout en évitant des obstacles. Ici, les bonus sont des votes, et les obstacles des éléments (pandémie, scandale…)</p>

        <div class="t-h1 u-marg-b-sm">Qui a produit ce jeu ?</div>

        <p class="t-text u-marg-b-md">Ce jeu a été imaginé et développé par <a href="https://brig.ht/" class="t-link" target="_blank">Bright</a>, agence de contenus & expériences digitales en 3D. Parmi nos précédents jeux : «<a href="https://prisme7.io/fr" class="t-link" target="_blank">Prisme7</a>» (le jeu vidéo du Centre Pompidou) ou encore «<a class="t-link" target="_blank" href="https://www.unpresidential.lol/">Unpresidential</a>», jeu de combat entre Trump et Biden, tous deux disponibles sur iOS et Android.</p>

        <div class="t-h1 u-marg-b-sm">Pourquoi avez-vous produit ce jeu ?</div>

        <p class="t-text u-marg-b-md">Bright produit des contenus en 3D, ludiques, éditorialisés. Plusieurs fois dans l’année, nous réalisons des contenus ayant des ambitions pédagogiques, car nous pensons que notre savoir-faire peut parfois être utile pour percevoir l’actualité sous des angles différents. Nous avons par exemple produit en 2020 «<a href="https://brig.ht/projects/dataviz-evolutive-covid-19-coronavirus-now" class="t-link" target="_blank">Coronavirus Now</a>», un dashboard en 3D et temps réel liés à des données épidémiques de la Covid-19.</p>

        <div class="t-h1 u-marg-b-sm">Le jeu favorise t-il un candidat ?</div>

        <p class="t-text u-marg-b-md">Ce jeu vise avant tout à permettre à faire découvrir le programme des candidats à un maximum de personnes. Il n’est ni militant, ni ne vise à favoriser tel ou tel candidat. Lors d’une partie, les candidats sont tirés au sort de façon aléatoire, sans pondération.</p>

        <div class="t-h1 u-marg-b-sm">D’où viennent les informations liées aux programmes des candidats ?</div>

        <p class="t-text u-marg-b-md">Afin qu’elles puissent être lisibles dans le jeu, nous avons édité sous une forme plus courte les propositions des candidats exprimées dans leurs programmes en ligne, ainsi que dans des articles de presse généraliste.</p>

        <div class="t-h1 u-marg-b-sm">Combien de propositions contient le jeu ?</div>

        <p class="t-text u-marg-b-md">Nous nous sommes limités à 50 propositions par candidat. Il s’agit naturellement des réponses sur lequel le joueur doit cliquer sur “Vrai” quand le joueur clic dessus pendant la partie. Celles qui n’appartiennent pas au candidat (et sur lesquelles le joueur doit cliquer sur “Faux”) sont de l’ordre d’un quart du nombre total des propositions par candidat.</p>

        <div class="t-h1 u-marg-b-sm">Captez vous / conservez vous des données liées aux joueurs ?</div>

        <p class="t-text u-marg-b-md">Nous ne stockons aucune donnée individuelle. Pour estimer le succès du jeu, nous récupérons néanmoins des statistiques de visite sur le site (nombre de visiteurs cumulés, nombre de visiteurs en même temps sur le site), en utilisant Google Analytics en anonymisant systématiquement les addresses IP.</p>

        <div class="t-h1 u-marg-b-sm">Comment vous contacter ?</div>

        <p class="t-text u-marg-b-md">N’hésitez pas ! Voici notre mail : <a href="mailto:contact@brig.ht" class="t-link">contact@brig.ht</a></p>
      </div>
      <div v-if="!$device.isMobile" class="u-black u-w2of12 u-marg-l-2of12">
        <img class="u-block u-full-width u-marg-t-xl" src="/images/vote.png">
      </div>
    </div>
  </app-overlay>
</template>

<script>
import AppOverlay from '@/components/AppOverlay'
import { socials } from '@/assets/data'

export default {

  components: {
    AppOverlay
  },

  data () {
    return {
      data: socials
    }
  }
}
</script>

<style lang="stylus">
.menu
  &__round
    height 55px
    width 55px
    padding 15px
</style>