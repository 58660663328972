import { RawShaderMaterial, Vector4, DoubleSide } from 'three'

import raf from '@internet/raf'

import Assets from '../../controllers/Assets'

import vert from './curved-material.vert'
import frag from './curved-material.frag'
import { Vector3 } from 'three'
import { Color } from 'three'

export default class CurvedMaterial extends RawShaderMaterial {

	constructor( {color} = props) {
		super({
			vertexShader: vert,
			fragmentShader: frag,
			uniforms: {
				texture: { value: Assets.textures.battery, type: 't' },
				color: { value: color || new Color(0x0000FF)  },
			},
			// opacity: 1.,
			// depthWrite: false,
			// transparent: true,
		})
	}

}
