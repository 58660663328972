import {

	DirectionalLight,
	Fog,
	AmbientLight,
	Vector3,
	Mesh,
	MeshBasicMaterial,
	SphereBufferGeometry,
	Quaternion,
	Object3D,
	Color,

} from 'three'

import Scene from '../abstractions/Scene'
import DebugCamera from '../components/cameras/DebugCamera'
import DebugStore from '../../store/DebugStore'
import Character from '../components/character/Character'
import Floor from '../components/floor/Floor'
// import Physics from '../controllers/Physics'
import DebugRenderer from '../utils/CannonDebug'
import MainLight from '../components/lights/MainLight'

import GameStore from '../../store/GameStore'

export default class OutroScene extends Scene {

	constructor() {
		super()
		/// #if DEVELOPMENT
		this.devTools()
		/// #endif
	}

	init() {
		this.base.fog = (new Fog(0xC0C0C0, 0, 100))
		this.base.background = new Color(0xC0C0C0)

		console.log(GameStore.currentStep.get())

		this.player = this.addComponent(new Character(
			{
				position

			}
		))
		this.floor = this.addComponent(new Floor())
		this.initLights()

		this.enter()
	}


	initLights() {
		this.directionalLight = this.addComponent(new MainLight())

		const ambientLight = new AmbientLight(0xF0F0F0, .9)
		this.add(ambientLight)
	}

	enter() {
		super.enter()
	}

	destroy() {
		/// #if DEVELOPMENT
		this.removeComponent(this.debugCamera)
		DebugStore.debugCamera.unsubscribe(this.toggleOrbitControl, this)
		DebugStore.debugPhysics.unsubscribe(this.toggleDebugPhysics, this)
		this.physicsDebugContainer = null
		this.debugCamera = null
		/// #endif
		super.destroy()
	}

	update() {
		super.update()

		/// #if DEVELOPMENT
		if (this.physicsDebugContainer.visible) {
			this.debugRenderer.update()
		}
		/// #endif
	}

	/// #if DEVELOPMENT
	devTools() {
		this.physicsDebugContainer = this.add(new Object3D())
		this.debugRenderer = new DebugRenderer(this.physicsDebugContainer, Physics.getWorld())
		this.debugCamera = this.addComponent(DebugCamera.get())
		DebugStore.debugCamera.subscribe(this.toggleOrbitControl, this)
		DebugStore.debugPhysics.subscribe(this.toggleDebugPhysics, this)
		this.toggleOrbitControl(DebugStore.debugCamera.get())
		this.toggleDebugPhysics(DebugStore.debugPhysics.get())
	}

	toggleDebugPhysics(v) {
		this.physicsDebugContainer.visible = v
	}

	toggleOrbitControl(v) {
		this.debugCamera.base.position.copy(new Vector3(0, 0, 5))
		this.debugCamera.base.quaternion.copy(new Quaternion())

		if (v) this.useCamera(this.debugCamera)
		else this.previousCamera && this.useCamera(this.previousCamera)
	}
	/// #endif

}
