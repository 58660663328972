let currentTiles = null
const tilesToSpawn = 2
let currentTilesIndex = -1
const tilesZpos = 80
let randomIndex = 0
const baseXLeft = 17
const baseXRight = -17
let previousTile = null
const decorationName = [
  "Lampaire",
  "Flag"
]

function getName(positionName) {
  return positionName.substring(0, positionName.indexOf('_'));
}

function setCurrentTilesIndex() {
  currentTilesIndex++
}

function getCurrentTiles(tiles, startIndex) {
  previousTile = currentTiles || null
  randomIndex = currentTiles ? Math.floor(Math.random() * tiles.length) : 0
  // currentTiles = tiles[0]
  currentTiles = tiles[startIndex || randomIndex]
  // console.log('CURRENT TILES', currentTiles.name) 
  return currentTiles
}


export {
  getCurrentTiles,
  getName,
  setCurrentTilesIndex,
  baseXLeft,
  tilesZpos,
  baseXRight,
  tilesToSpawn,
  currentTilesIndex
}