/**
 * @author SolalDR / https://solaldussout-revel.fr/
 */

class VideoLoader {

	load( url, onLoad, onProgress, onError ) {
		return new Promise( function ( resolve, reject ) {
			const xhr = new XMLHttpRequest()
			xhr.responseType = 'blob'
			xhr.open( 'GET', url )

			xhr.onload = function () {
				if ( this.status === 200 ) {
					const blob = this.response
					const video = document.createElement( 'video' )
					video.src = window.URL.createObjectURL( blob )
					// console.log('EH OUI', video)
					onLoad( video )
					resolve( video )
				}
			}
			xhr.onerror = function ( e ) {
				reject( new Error( 'Error ' + e.target.status + ' occurred while receiving the document.' ) )
			}

			xhr.send()
		} )
	}

}

export default VideoLoader
