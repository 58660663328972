<template>
  <div class="share u-row u-middle">
    <div class="share__items u-relative" @mouseleave="onOut" @mouseenter="onEnter">
      <div class="share__items__list">
        <transition-group :duration="2000" name="t-translate--fade-left">
          <div
            class="u-relative"
            :key="social.name"
            v-if="isHover"
            v-for="(social, index) in socials"
            :style="{transitionDelay: (.05 *  (socials.length - index)) + 's'}"
          >
            <app-button-round
              :big="true"
              :url="social.url"
              @click="onLink(social.slug)"
              class="u-marg-b-sm"
            >
              <svg class="u-full-width u-full-height u-fill-red" preserveAspectRatio="xMidYMid meet">
                <use :xlink:href="'#' + social.icon"/>
              </svg>
            </app-button-round>
            <transition name="t-shitty-fader">
              <div v-if="social.slug === 'copy' && wording" class="share__items__list__label t-text u-white">
                Lien copié!
              </div>
            </transition>
          </div>
        </transition-group>
      </div>
      <app-button-round @click="isHover = !isHover" :big="true">
        <svg class="u-full-width u-full-height u-fill-red" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#share"/>
        </svg>
      </app-button-round>

    </div>
    <app-button-round class="u-marg-l-sm" @click="$emit('about')" :big="true">
      <svg class="u-full-width u-full-height" preserveAspectRatio="xMidYMid meet">
        <use xlink:href="#ired"/>
      </svg>
    </app-button-round>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'

import CopyPaste from '@/utils/CopyPaste'

import { socials } from '@/assets/data'

export default {
  components: {
    AppButtonRound,
    AppButton
  },

  data () {
    return {
      isHover: false,
      socials: socials,
      wording: false
    }
  },

  methods: {
    onHover () {
      this.isHover = !this.isHover
    },
    onEnter () {
      clearTimeout(this.timeout)
    },
    onOut () {
      this.timeout = setTimeout(() => {
        this.isHover = false
      }, 3000)
    },
    onLink (slug) {
      if (slug === 'copy') {
        CopyPaste.makeCopy({values: window.location.origin})

        this.wording = true
        setTimeout(() => {
          this.wording = false
        }, 1000)
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.share
  position absolute
  bottom: $spacings.md
  left: $spacings.md
  
  &__items__list
    position absolute
    bottom 100%
    left 0

    &__label
      position absolute
      left calc(100% + 20px)
      top 50%
      transform translateY(-50%)
</style>