import { Mesh, PlaneBufferGeometry, MeshBasicMaterial } from 'three'

import Scene from '../abstractions/Scene'
import Assets from '../controllers/Assets'
import Character from '../components/character/Character'
import Coin from '../components/coin/coin'

export default class PrerenderScene extends Scene {

	init() {
		this.items = []
		const mesh = new Mesh(new PlaneBufferGeometry(10, 10), new MeshBasicMaterial())

		for (const key in Assets.textures) {
			if (Assets.textures.hasOwnProperty(key)) {
				const element = Assets.textures[key]
				const meshClone = mesh.clone()
				const mat = mesh.material.clone()
				mesh.material = mat
				mesh.material.map = element
				// debugger
				mesh.position.set(Math.random() * 10, Math.random() * 10, Math.random() * 10)
				this.items.push(meshClone)
			}
		}

		// this.addComponent(new Character({
		// 	id: 'player-1',
		// 	model: "character1"
		// }))

		this.addComponent(new Coin())
	}

	update() {
		if (this.currentItem) this.removeComponent(this.currentItem)

		const item = this.items.shift()


		if (!item) {
			this.prerendered = true
			return
		}
		// console.log( 'prerendering' )
		this.add(item)
		// for ( let i = 0; i < this.items.length; i++ ) {
		// 	const element = this.items[ i ]
		// 	this.add( element )
		// }
	}


}
