<template>
  <app-overlay @close="$emit('close')">
    <div v-for="datum in data" class="u-row u-middle u-marg-b-sm u-marg-t-md u-cursor-pointer" @click="$emit(datum.event)">
      <div class="menu__round u-round" :class="`u-bg-${datum.color}`">
        <svg class="u-full-width u-full-height u-fill-white" preserveAspectRatio="xMidYMid meet">
          <use :xlink:href="`#${datum.svg}`"/>
        </svg>
      </div>
      <div class="t-h1 u-pad-l-0 u-pad-l-sm--xs" :class="`u-${datum.color}`">
        {{ datum.title }}
      </div>
    </div>
    <router-link v-if="!$route.name.includes('Homepage')" :to="{name: 'Homepage'}" class="u-row u-middle u-marg-t-md u-cursor-pointer">
      <div class="menu__round u-round u-bg-red">
        <svg class="u-full-width u-full-height u-fill-white" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#out"/>
        </svg>
      </div>
      <div class="t-h1 u-pad-l-0 u-pad-l-sm--xs u-red">
        ABANDONNER
      </div>
    </router-link>
  </app-overlay>
</template>

<script>
import AppOverlay from '@/components/AppOverlay'

export default {

  components: {
    AppOverlay
  },

  data () {
    return {
      data: [
        {
          title: 'PARTAGER',
          svg: 'share',
          color: 'red',
          event: 'share'
        },
        {
          title: 'À PROPOS',
          svg: 'i',
          color: 'blue',
          event: 'about'
        }
      ]
    }
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="stylus">
.menu
  &__round
    height 55px
    width 55px
    padding 15px
</style>