<template>
  <div class="result u-wrapper-panel u-row u-center u-middle">
    <div class="u-w8of12 u-w10of12--md u-relative">
      <div class="result__wrapper u-relative u-radius u-overflow-hidden">
        <div class="result__content result__fader u-relative">
          <div class="t-text u-red u-center t-black">
            Bravo, les votes sont clos !!!
          </div>
          <div class="u-radius u-bg-white u-center u-pad-y-md u-pad-y-sm--sm u-marg-t-sm">
            <div>
              <scoring :score="totalScore" />
            </div>
            <div class="u-flex u-evenly u-marg-t-lg u-marg-t-md--sm u-left--sm u-nowrap--sm u-full-width u-overflow-x-scroll--sm u-pad-x-md">
              <div class="result__card u-w3of12 u-w10of12--sm u-center u-pad-x-sm u-pad-l-0--sm u-pad-r-sm--sm" v-for="candidate in candidates">
                <div class="u-w9of12 u-inline-block u-box-1by1 u-round u-round u-overflow-hidden" :style="{backgroundColor: candidate.color}">
                  <app-image v-if="candidate.game.image.length" :id="candidate.game.image[0].id" class="u-wrapper-panel u-fit-cover" />
                </div>
                <div class="result__bg__blue u-marg-t-sm u-radius u-pad-sm">
                  <div class="t-text u-blue u-marg-b-sm">
                    {{ candidate.shortName }}
                  </div>
                  <scoring :score="candidate.score" :big="false" />
                </div>
              </div>
            </div>
            <div class="u-row u-center u-marg-t-md u-marg-t-sm--sm u-pad-x-md">
              <app-button :to="{name: 'Homepage'}" :isGhost="true" :icon="true" class="u-marg-r-md u-marg-r-0--sm u-marg-b-sm--sm">
                <svg v-if="!$device.isMobile" class="t-icon t-icon--sm u-fill-blue u-marg-r-sm" preserveAspectRatio="xMidYMid meet">
                  <use xlink:href="#out"/>
                </svg>
                Revenir au QG
              </app-button>
              <app-button @click="$emit('restart')" :icon="true" >
                <svg v-if="!$device.isMobile" class="t-icon t-icon--sm u-fill-white u-marg-r-sm" preserveAspectRatio="xMidYMid meet">
                  <use xlink:href="#reload"/>
                </svg>
                Relancer une campagne
              </app-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="result__picto result__fader u-round u-box-1by1 u-bg-blue">
        <div class="result__picto__wrapper u-wrapper-panel">
          <img class="u-fit-contain u-wrapper-panel" src="/images/smiley.png">
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Scoring from '@/components/Scoring'

export default {
  components: {
    Scoring
  },
  computed: {
    totalScore () {
      return this.$store.getters['data/score']
    }
  },

  props: {
    candidates: {
      type: Array,
      default: () => {return []}
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.result
  
  &__card
    flex-shrink 0
    // +mq($until: 'tablet')
    //   width 200px

  &__wrapper
    padding: 20px 30px 30px
    
    +mq($until: 'tablet')
      padding: 15px 15px 15px

    &:before
      position absolute
      content ''
      top 0
      left 0 
      width 100%
      height 100%
      background-color: $colors.white
      transform scale(1, 1)
      transition transform .8s .4s ease-in-out-quart
      opacity .7
      animation 1.5s ease-in-out-quad 1 intro

  &__fader
    transition opacity .5s linear
    opacity 1

  &.custom-enter-active &__fader
    transition opacity .7s 1s linear
    
  &.custom-enter-from &__fader
    opacity 0

  &.custom-leave-to &__fader
    opacity 0

  &.custom-leave-to &__wrapper:before
    transform scale(0, 0)
  
  &__bg__blue
    background-color #E3F4FF

  &__picto
    width 100px
    position absolute !important
    top -50px
    left calc(50% - 50px)

    &__wrapper
      padding 20%

@keyframes intro
  0%
    transform scale(0, .1) translateZ(0)

  50%
    transform scale(1, .1) translateZ(0)

  100%
    transform scale(1, 1) translateZ(0)
</style>