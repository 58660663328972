import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {

  getInfosCandidats () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchInfosCandidats {
            candidats: entries(site: "${site}", type: "infoCandidat") {
              id
              uid
              slug
              title
              color
              party: partiPolitique
              position: position2d {
                x: col1
                y: col2
              }
              media {
                id
                url
              }
              infoCandidat {
                ... on infoCandidat_chapter_BlockType {
                  id
                  type: typeHandle
                  title: titre
                }
                ... on infoCandidat_items_BlockType {
                  id
                  type: typeHandle
                  text
                  picto {
                    url
                    id
                  }
                }
              }
              game: stKeypointCandidat {
                ... on stKeypoint_stKeypoint_Entry {
                  id
                  name: title
                  image {
                    id
                    url
                  }
                  stQuestions {
                    ... on stQuestions_questions_BlockType {
                      id
                      title: titre
                      answers {
                        label: col1
                        value: col2
                      }
                    }
                  }
                  image {
                    id
                    url
                  }
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.candidats

        resolve(result)
        storage.setItem( `fetchInfosCandidats_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching candidats params ', err)
      })
    })
    const cache = storage.getItem( `fetchInfosCandidats_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getEntries (ids) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEntries ($id: [QueryArgument]) {
            entries (site: "${site}") {
              id
            }
          }
        `,
        variables: { id: ids }
      })
      .then(res => {
        const result = res.data.entries

        resolve(result)
        storage.setItem( `fetchEntries_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchEntries_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()