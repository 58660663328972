import { ShaderMaterial, BackSide, Color } from 'three'

import vert from './sky-material.vert'
import frag from './sky-material.frag'

export default class SkyMaterial extends ShaderMaterial {

  constructor(props) {
    super({
      vertexShader: vert,
      fragmentShader: frag,
      uniforms: {
        topColor: { value: new Color(0x8ADAFF) },
        bottomColor: { value: new Color(0xC4D5F6) },
        offset: { value: 20 },
        exponent: { value: 0.9 }
      },
      side: BackSide
    })
  }

}
