import {
  Mesh,
  PlaneBufferGeometry,
  InstancedBufferGeometry,
  InstancedInterleavedBuffer,
  InterleavedBufferAttribute,
  InstancedBufferAttribute,
  Vector3,
  InstancedMesh,
  Color,
  DynamicDrawUsage,
  Matrix4,
  MeshStandardMaterial,
  Euler,
  Quaternion,
  Object3D
} from 'three'


import GameObject from '../../abstractions/GameObject'

import Assets from '../../controllers/Assets'

import Files from '../../../managers/assetsManager/Files'
import { Colors } from '../../../utils/styleguide'
import { gameConfig } from '../../config/gameConfig'

const COUNT = 18
let material
let zposArr = []
const geoPool = []

export default class Barriers extends GameObject {

  constructor(props = {}) {
    super(props)

    // this.geo = getGeometry()
    const mesh = Files.get('game').barricade.scene.children[0]
    this.geo = mesh.geometry

    if (!material) material = new MeshStandardMaterial({ roughness: 0.76, metalness: 0.96 })
    // const material = mesh.material
    const colors = [new Color(Colors.blue), new Color('white'), new Color(Colors.red)]

    const instanceColors = [];
    let colorIndex = 0
    for (let i = 0; i < COUNT; i++) {

      instanceColors.push(colors[colorIndex].r);
      instanceColors.push(colors[colorIndex].g);
      instanceColors.push(colors[colorIndex].b);

      if ((i - 1) % 2 == 0) colorIndex++
      if (colorIndex > 2) colorIndex = 0
    }

    const colorParsChunk = [
      'attribute vec3 instanceColor;',
      'varying vec3 vInstanceColor;',
      'vec3 displace(vec3 v) {',
      'float dist = distance(v.z, cameraPosition.z);',
      'if( dist < 0.0) { dist = 0.0; }',
      'float addY = dist * dist;',
      `return vec3(v.x, v.y - addY * (1. * ${gameConfig.bendY.toString()}), v.z);`,
      '}',
      '#include <common>'
    ].join('\n');

    const instanceColorChunk = [
      '#include <worldpos_vertex>',
      '\tvInstanceColor = instanceColor;',
      // 'transformedNormal = normalMatrix * transformedNormal;',
      'transformed = worldPosition.xyz;',
      'transformed = displace(transformed);',
      '	float e = -.01;',
      'vec3 dA = e * normalize(cross(normal.yzx, normal));',
      'vec3 dB = e * normalize(cross(dA, normal));',
      'vec3 pA = worldPosition.xyz + dA ;',
      'vec3 pB = worldPosition.xyz + dB ;',
      'vec3 hA = displace(pA);',
      'vec3 hB =  displace(pB);',
      'vec4 testNormal = vec4(cross(hA-transformed, hB-transformed), 1.);',
      'vNormal = normalize(normalMatrix * testNormal.xyz);',
      'vNormal = normalize(transformedNormal);',
      'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
    ].join('\n');

    const fragmentParsChunk = [
      'varying vec3 vInstanceColor;',
      '#include <common>'
    ].join('\n');

    const colorChunk = [
      // 'vec4 diffuseColor = vec4( vNormal , opacity );'
      'vec4 diffuseColor = vec4( diffuse * vInstanceColor, opacity );'
    ].join('\n');

    material.onBeforeCompile = function (shader) {

      shader.vertexShader = shader.vertexShader
        .replace('#include <common>', colorParsChunk)
        .replace('#include <worldpos_vertex>', instanceColorChunk);

      shader.fragmentShader = shader.fragmentShader
        .replace('#include <common>', fragmentParsChunk)
        .replace('vec4 diffuseColor = vec4( diffuse, opacity );', colorChunk);

      //console.log( shader.uniforms );
      //console.log( shader.vertexShader );
      //console.log( shader.fragmentShader );

    };

    this.geo.setAttribute('instanceColor', new InstancedBufferAttribute(new Float32Array(instanceColors), 3));

    this.geo.computeVertexNormals();

    this.base = new InstancedMesh(this.geo, material, COUNT)
    this.base.castShadow = true
    // this.base.receiveShadow = true
    this.base.instanceMatrix.setUsage(DynamicDrawUsage);
    this.base.frustumCulled = true
    let zpos = -10
    for (let i = 0; i < COUNT; i++) {
      let matrix = new Matrix4();

      let position = new Vector3();
      let rotation = new Euler();
      let quaternion = new Quaternion();
      let scale = new Vector3();

      position.x = i % 2 ? -9 : 9
      position.y = 0
      position.z = zpos
      zposArr.push(zpos)
      rotation.x = 0
      rotation.z = 1.5;
      if ((i - 1) % 2 == 0) zpos += 9.7

      quaternion.setFromEuler(rotation);

      scale.x = scale.y = scale.z = .235;

      matrix.compose(position, quaternion, scale);

      this.base.setMatrixAt(i, matrix);
    }



    this.bind()
  }

  bind() {
  }

  unbind() {
  }


  update(dt) {
    super.update(dt)

    for (let i = 0; i < COUNT; i++) {
      let matrix = new Matrix4();

      let position = new Vector3();
      let rotation = new Euler();
      let quaternion = new Quaternion();
      let scale = new Vector3();
      zposArr[i] = zposArr[i] - (gameConfig.barriersSpeed * gameConfig.globalSpeed * gameConfig.speed)
      position.x = i % 2 ? -9.9 : 9.9
      position.y = 0
      position.z = zposArr[i]

      rotation.x = 0
      rotation.z = 1.5;

      if (zposArr[i] < -20) {
        zposArr[i] = 67
      }


      quaternion.setFromEuler(rotation);

      scale.x = scale.y = scale.z = .235;

      matrix.compose(position, quaternion, scale);

      this.base.setMatrixAt(i, matrix);
    }


    this.base.instanceMatrix.needsUpdate = true;
  }

  beforeDestroy() {
    geoPool.push(this.geo)
    this.data = this.geo = null
    this.unbind()
  }


  destroy() {
    this.geo.dispose()
    super.destroy()

  }

}


