import { Color, PerspectiveCamera } from 'three'
import { TweenMax, Expo } from 'gsap/all'
import { lerp, map } from '@internet/maths'

import CanvasStore from '../../store/CanvasStore'

import Scenes from '../controllers/Scenes'
import postprocessVS from '../shaders/passes/postprocess.vert'
import postprocessFS from '../shaders/passes/postprocess.frag'

import Transitions from '../controllers/Transitions'

import createBuffer from './createBuffer'
import createFilter from './createFilter'

// const gui = _gui.folder( 'Post Processing' );
// gui.open()

const buffers = {}
const filters = {}

const tweens = {}
let currentSceneBuffer
let renderer
let initialized = false
const colors = {}


const prerenderCam = new PerspectiveCamera(245, 1, 0.001, 100000)
prerenderCam.position.z = 80

// Create filter shaders and render buffers
function init(opts = {}) {
	if (initialized) return
	initialized = true
	renderer = opts.renderer

	colors.black = new Color(0x000000)

	// buffers.prerender = createBuffer({ renderer, depth: false, alpha: false })
	// buffers.scene1 = createBuffer({ renderer, depth: true, alpha: false })

	// filters.postprocess = createFilter(postprocessVS, postprocessFS, {
	// 	uniforms: Object.assign({
	// 		res: { value: [0, 0, 0, 0] },
	// 		ratio: { value: 0 },
	// 		scene: { type: 't', value: null },
	// 		panelValue: Transitions.uniforms.panelValue
	// 	}),
	// 	defines: {
	// 	}
	// })

	// CanvasStore.canvasPixelRatio.subscribe(v => (filters.postprocess.uniforms.ratio.value = v))
	// filters.postprocess.uniforms.ratio.value = CanvasStore.canvasPixelRatio.get()

	// updateOverlay( 16.67, true );

	// Use snapshot to prepare buffers
	snapshot()
}

function snapshot() {
	// filters.postprocess.uniforms.scene.value = buffers.scene1.texture
	// filters.postprocess.uniforms.scene.needsUpdate = true
	// currentSceneBuffer = buffers.scene1
}

// Resize buffers and update size uniforms on filters
function resize() {
	// const size = CanvasStore.drawingBufferSize.get()

	// buffers.scene1.setSize(size[0], size[1])
	// buffers.prerender.setSize(10, 10)

	// filters.postprocess.uniforms.res.value[0] = size[0]
	// filters.postprocess.uniforms.res.value[1] = size[1]
	// filters.postprocess.uniforms.res.value[2] = 1 / size[0]
	// filters.postprocess.uniforms.res.value[3] = 1 / size[1]
	// filters.postprocess.uniforms.res.needsUpdate = true
}

function appLoaded() {
}

function appReady() {
	switchScenes()
}


function switchScenes(to) {
	if (!to) {
		// console.log('YEAH/')
		// Scenes.set( 'game', { gameId: 'tutorial' } )

		Scenes.set('default')
	}
}


function update(dt) {


	const scene = Scenes.getActive()
	if (scene) scene.update(dt)
}

function clear(color = colors.black, alpha = 0) {
	renderer.setClearColor(color, alpha)
	renderer.clear()
}

function renderScene(instance, buffer) {
	// renderer.setRenderTarget(buffer)
	if (!instance) return clear()

	const scene = instance.scene
	const cam = instance.currentCamera ? instance.currentCamera.cam : null
	if (!scene || !cam) return clear()

	if (instance.background) clear(instance.background, 1)

	renderer.render(scene, cam)
}

function prerender(scene, needsUpdate) {
	console.log('Prerender')
	renderer.setRenderTarget(buffers.prerender)
	if (needsUpdate) scene.update(16.67)
	renderer.render(scene.scene, prerenderCam)
	buffers.prerender.dispose()
}


function render(dt, forceScene = null) {
	// Render Scene
	renderer.sortObjects = true
	const scene = forceScene || Scenes.getActive()

	renderScene(scene, currentSceneBuffer)


	// Render UI

	// renderer.clearDepth();
	renderer.sortObjects = false

	// Render ui behind the postprocess stack

	// Update post process uniform and render post process
	// filters.postprocess.uniforms.time.value += ( dt / 1000. );
	// renderer.setRenderTarget(null)
	// renderer.render(filters.postprocess.scene, filters.postprocess.cam)

	// Render ui on top of the postprocess stack
}



export default {
	update,
	render,
	init,
	appLoaded,
	appReady,
	resize,
	snapshot,
	prerender,
	filters,
	isReady: () => initialized
}
