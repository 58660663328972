import { ShaderLib, UniformsUtils, MeshStandardMaterial } from 'three'

import raf from '@internet/raf'

import vert from './character-material.vert'
import frag from './character-material.frag'

export default class CharacterMaterial extends MeshStandardMaterial {

	constructor( props ) {
		super( props )
		this.vertexShader = vert
		this.fragmentShader = frag

		this.type = 'PhysicalMaterial'
		this.uniforms = {
			...UniformsUtils.clone( ShaderLib.physical.uniforms )
		}
		// Object.assign( this.uniforms,, )
	}

}
