// import parseQuerystring from 'app/utilities/parseQuerystring';

import w from '../utils/store/writeable'
import r from '../utils/store/readable'

let dev = false
/// #if DEVELOPMENT
dev = false
/// #endif

const hasTouch = !!('ontouchstart' in window)

const DebugStore = Object.freeze({


	noGameOver: r(false),


	debug: w(hasTouch ? 0 : 0),
	debugCamera: w(false),
	debugPhysics: w(false),

	orbitCameraStats: r({ position: null, rotation: null }),

	// useGui: r(true),
	useGui: r(!!dev),
	showStats: r(!!dev),

})

export default DebugStore
