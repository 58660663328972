import DefaultScene from '../scenes/DefaultScene'
import PrerenderScene from '../scenes/PrerenderScene'
import IntroScene from '../scenes/IntroScene'
import GameStore from '../../store/GameStore'
import GameScene from '../scenes/GameScene'
import OutroScene from '../scenes/OutroScene'
import AppStore from '../../store/AppStore'
let activeSceneClass
let activeSceneType
let currentScene = null
const scenes = {
	default: null,
	prerender: null,
}

const instances = {
	default: DefaultScene,
	intro: IntroScene,
	game: GameScene,
	outro: OutroScene,
	prerender: PrerenderScene,
}
let ready = null

function init() {

}

function appReady() {
	ready = true
	bind()
}

function bind() {
	AppStore.currentView.subscribe(updateScene, this)
	GameStore.goToCurrentStep.subscribe(onGoToCurrentStep, this)
}

function destroy(types = []) {
	types.forEach(type => {
		if (!scenes[type]) return
		scenes[type].destroy()
		scenes[type] = null
	})
}

function deactivate(types = []) {
	types.forEach(type => {
		if (!scenes[type]) return
		scenes[type].deactivated()
	})
}

function get(type) {
	return scenes[type]
}

function updateScene(type) {
	activeSceneType = type
	const sceneName = instances[type] ? type : "default"

	set(sceneName)
}

function onGoToCurrentStep() {
	updateScene(GameStore.currentView.get())
}

function set(type, props = {}) {
	if (type === currentScene) return
	if (!ready) {
		// defferScene = [ type, props ]
		return
	}

	if (!type || !instances[type]) type = 'default'

	deactivate(['prerender', 'default', 'game', 'outro', "intro"])
	destroy(['prerender', 'default', 'game', 'outro', "intro"])
	const Instance = instances[type]

	currentScene = type

	if (!scenes[type]) scenes[type] = new Instance(props)
	scenes[type].activated(props)
}

function getActive() {
	if (scenes.game) return scenes.game
	if (scenes.outro) return scenes.outro
	if (scenes.intro) return scenes.intro
}

export default {
	init,
	appReady,
	set,
	get,
	getActive
}
