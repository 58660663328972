
class ImageLoader {

	load(url, onLoad, onProgress, onError) {
		return new Promise(function (resolve, reject) {
			const xhr = new XMLHttpRequest()
			xhr.responseType = 'blob'
			xhr.open('GET', url)

			xhr.onload = function () {
				if (this.status === 200) {
					const blob = this.response
					const image = new Image()

					const urlCreator = window.URL || window.webkitURL
					image.src = urlCreator.createObjectURL(blob)
					onLoad(image)
					resolve(image)
				}
			}
			xhr.onerror = function (e) {
				reject(new Error('Error ' + e.target.status + ' occurred while receiving the document.'))
			}

			xhr.send()
		})
	}

}

export default ImageLoader
