import { PerspectiveCamera, Vector3 } from 'three'

import CanvasStore from '../../store/CanvasStore'
import { isMobile } from '../../utils'

import BaseComponent from './BaseComponent'

const ratio = window.innerWidth / window.innerHeight


const mobileRatio = {
	1:{ratio:0.65, fov: 85},
	2:{ratio:0.56, fov: 90},
	3:{ratio:0.45, fov: 105},
}

let fovMobile = null
function getMobileFov() {
	const keys = Object.keys(mobileRatio);
	keys.forEach((key, index) => {
		if(ratio > mobileRatio[key].ratio && fovMobile === null) {
			fovMobile = mobileRatio[key].fov
		}
	});

	return fovMobile ? fovMobile: mobileRatio[3].fov
}

function defaultCamera(ctx) {
	ctx.cam = new PerspectiveCamera(isMobile() ? getMobileFov() : 55, window.innerWidth / window.innerHeight, 0.1, 500)
	ctx.base = ctx.cam
	ctx.base.position
		.set(0, 0.2, 1)
		.multiplyScalar(5)
	ctx.base.lookAt(new Vector3(0, 0, 0))
}

class Camera extends BaseComponent {

	constructor(props = {}) {
		super()
		this.isCamera = true
		if (!props.noInit) this.init(props)
		if (!this.props) this.props = props
		if (!this.base) defaultCamera(this)

		this.resizeSignal = CanvasStore.canvasSize.subscribe(this.resize, this)
		this.resize(CanvasStore.canvasSize.current)
	}

	update() {
	}

	resize(s) {
		this.cam.aspect = s[0] / s[1]
		this.cam.updateProjectionMatrix()
	}

	beforeDestroy() {
		CanvasStore.canvasSize.unsubscribe(this.resizeSignal, this)
	}

	destroy() {
		this.cam = null
		super.destroy()
	}

}

export default Camera
