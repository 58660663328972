<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="main u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <div id="canvas" ref="containerWebGL" class="u-wrapper-panel"/>
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component v-if="loaded" :is="Component" />
      </transition>
    </router-view>

    <!-- SHARE -->
    <transition name="t-fader">
      <share v-if="!$device.isMobile && $route.name !== 'Candidat'" @about="isAbout = true" />
    </transition>

    <!-- MENU -->
    <transition name="t-fader">
      <app-button-round @click="isMenu = true" class="main__btn-menu" v-show="$device.isMobile && $route.name !== 'Candidat'">
        <svg class="u-wrapper-panel u-stroke-red" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#menu"/>
        </svg>
      </app-button-round>
    </transition>

    <transition name="t-fader">
      <app-menu-mobile v-if="isMenu" @share="isShareMobile = true" @about="isAbout = true" @close="isMenu = false" />
    </transition>
    <transition name="t-fader">
      <share-mobile v-if="isShareMobile" @close="isShareMobile = false" />
    </transition>
    <transition name="t-fader">
      <about v-if="isAbout" @close="isAbout = false" />
    </transition>

    <transition :duration="2000" name="custom">
      <loader v-if="!loaded" />
    </transition>

    <transition :duration="2000" name="custom">
      <transition-screen v-if="isTransition" />
    </transition>

    <cookie />
  </div>
</template>

<script>
import App from './App'


import WebGL from '@/webGL/WebGL'
import Loader from '@/components/common/Loader'
import TransitionScreen from '@/components/common/TransitionScreen'
import AppButtonRound from '@/components/common/AppButtonRound'

import SymbolsSvg from '@/components/SymbolsSvg'
import PopinLeave from '@/components/PopinLeave'
import Share from '@/components/Share'
import AppMenuMobile from '@/components/AppMenuMobile'
import ShareMobile from '@/components/ShareMobile'
import About from '@/components/About'

import Cookie from '@/components/common/cookie'

import { audioBg } from '@/utils/Audio'
import { sounds } from '@/assets/data'

import {
  AmbientLight,
  SphereBufferGeometry,
  MeshNormalMaterial,
  Mesh
} from 'three'

export default {
  name: 'App',

  mixins: [App],

  components: {
    Loader,
    SymbolsSvg,
    AppButtonRound,
    TransitionScreen,
    Share,
    AppMenuMobile,
    ShareMobile,
    Cookie,
    About
  },

  data () {
    return {
      isMenu: false,
      isShareMobile: false,
      isTransition: false,
      isAbout: false
    }
  },

  computed: {
    loaded () {
      return this.$store.getters['data/loaded']
    }
  },

  created () {
    this.$store.dispatch('data/infoCandidats')
  },

  mounted () {
    this.onResize()
    WebGL.init()

    this.$hub.on('transition:on', () => {
      this.isTransition = true
    })
    this.$hub.on('transition:off', () => {
      this.isTransition = false
    })

    audioBg.player.loop = true
    audioBg.player.volume = .2
    audioBg.src = sounds.background
  },

  methods: {
    onUpdate (time) {
      if (this.controls) {
        this.controls.update()
      }
      this._onUpdate(time)
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
@import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto
  
.main
  &__btn-menu
    position absolute
    top: $spacings.md
    right: $spacings.md

</style>