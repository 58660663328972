import { Skeleton } from 'three'

const skeletonMesh = {}

export const cloneGLTF = ( gltf ) => {
	const clone = {
	  animations: gltf.animations,
	  scene: gltf.scene.clone( true )
	}

	const skinnedMeshes = {}

	skinnedMeshes[ gltf.scene.children[ 0 ].children[ 1 ].name ] = gltf.scene.children[ 0 ].children[ 1 ]


	const cloneBones = {}
	const cloneSkinnedMeshes = {}

	clone.scene.traverse( node => {
	  if ( node.isBone ) {
			cloneBones[ node.name ] = node
	  }
	} )

	cloneSkinnedMeshes[ clone.scene.children[ 0 ].children[ 1 ].name ] = clone.scene.children[ 0 ].children[ 1 ]

	for ( const name in skinnedMeshes ) {
	  const skinnedMesh = skinnedMeshes[ name ]
	  const skeleton = skinnedMesh.skeleton
	  const cloneSkinnedMesh = cloneSkinnedMeshes[ name ]

	  const orderedCloneBones = []

	  for ( let i = 0; i < skeleton.bones.length; ++i ) {
			const cloneBone = cloneBones[ skeleton.bones[ i ].name ]
			orderedCloneBones.push( cloneBone )
	  }


		skeletonMesh[ name ] = new Skeleton( orderedCloneBones, skeleton.boneInverses )


	  cloneSkinnedMesh.bind(
		 skeletonMesh[ name ],
		  cloneSkinnedMesh.matrixWorld )
	}


	return clone
}
