import w from '../utils/store/writeable'
import Signal from '../utils/store/Signal'

const AppStore = {
	viewportSize: w([window.innerWidth, window.innerHeight]),
	viewportRatio: w(null),
	siteVisible: w(true),
	gameStarted: w(false),
	currentView: w(null),
	currentStep: w(null),
	appReady: w(false),
	answerRight: w(null),
	introSkipped: w(false),
	gameInit: w(false),
	swipe: new Signal(),
	keyUp: new Signal(),
	keyDown: new Signal()
}

export default AppStore
