import { TweenLite, Power4, Power2, Expo } from "gsap/all"

import Scene from "../abstractions/Scene"

import AppStore from "../../store/AppStore"

import GameStore from "../../store/GameStore"

import Scenes from "./Scenes"

const uniforms = {
	panelValue: {
		value: 0
	}
}

let panelState = false

function init() {
	AppStore.currentView.subscribe(onViewChange)
}

function getTween(from = uniforms.panelValue.value, to = 1) {
	return new Promise((resolve) => {
		TweenLite.fromTo(uniforms.panelValue, 0.8, { value: from }, {
			value: to,
			ease: Expo.easeInOut,
			onComplete: () => {
				resolve()
			}
		})
	})
}

function onViewChange(view) {
	if (!AppStore.appReady.get()) return
	if (view === "connection") {
		Promise.resolve()
			.then(() => {
				if (!panelState) {
					panelState = true
					return getTween(-0.01, -1.1)
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				Scenes.set('connection')
				if (panelState) {
					panelState = false
					getTween(0.01, 1.1)
				}
			})
	}
	if (view === "game") {
		Promise.resolve()
			.then(() => {
				if (!panelState) {
					panelState = true
					return getTween(-0.01, -1.1)
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				if (panelState) {
					panelState = false
					getTween(0.01, 1.1)
				}
			})
	}


	if (view === "outro") {
		Promise.resolve()
			.then(() => {
				if (!panelState) {
					panelState = true
					return getTween(-0.01, -1.1)
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				GameStore.updatePositions.dispatch(false)
				if (panelState) {
					panelState = false

					getTween(0.01, 1.1)
				}
			})
	}


}

export default {
	init,
	uniforms,
	onViewChange
}
