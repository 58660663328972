// import PDBLoader from './loaders/PDBLoader'
// import HDRCubeTextureLoader from './loaders/HDRCubeTextureLoader'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

// import * as THREE from 'three'

// import BMFontLoader from './loaders/BMFontLoader'
import { UnsignedByteType } from 'three'

// import SoundLoader from './loaders/SoundLoader'
import VideoLoader from './loaders/VideoLoader'

import Files from "./Files"
import ImageLoader from './loaders/ImageLoader'
import { MeshoptDecoder } from './libs/meshopt_decoder.module';

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')
dracoLoader.setDecoderConfig({ type: 'js' }) // (Optional) Override detection of WASM support.

const gltfLoader = new GLTFLoader()
gltfLoader.setMeshoptDecoder(MeshoptDecoder);
gltfLoader.setDRACOLoader(dracoLoader)

const rgbeLoader = new RGBELoader()

const rules = [
	{
		test: /\.(?:glb|gltf)/,
		loader: gltfLoader
	},
	// {
	// 	test: /\.(?:drc)/,
	// 	loader: dracoLoader
	// },
	{
		test: /\.(?:png|jpg|jpeg)/,
		loader: new ImageLoader()
	},
	// {
	// 	test: /\.(?:mp3|wav)/,
	// 	loader: new SoundLoader()
	// },
	{
		test: /\.(?:mp4)/,
		loader: new VideoLoader()
	},
	{
		test: /\.(?:hdr)/,
		loader: rgbeLoader
	},
	// {
	// 	test: /\.(?:pdb)/,
	// 	loader: new PDBLoader()
	// },
	// {
	// 	test: /\.(?:hdr)/,
	// 	loader: new HDRCubeTextureLoader()
	// },
]

class FileLoader {

	/**
	 * @constructor
	 * @param {[{test: RegExp, loader: Object}]} rules
	 */
	constructor() {
		this.pendingFiles = []
		this.loadedFiles = []
		this.groups = []
		this.verbose = false
		this.progress = 0
	}

	/**
	 * Test if a group is loaded
	 * @param {string|null} group
	 */
	isLoaded(group = null) {
		if (group === null && this.pendingFiles.length > 0) {
			return false
		}

		let isLoaded = true
		if (group !== null) {
			this.pendingFiles.forEach(file => {
				if (file.groups.includes(group)) {
					isLoaded = false
				}
			})
		}

		return isLoaded
	}

	/**
	 * Return the list of loaded file in a specific group
	 * @param {string|null} group
	 */
	/**
	 * General onLoad callback
	 * @private
	 */
	onLoad(file, result, resolve) {
		Files.add(file.name, result, file.groups)

		this.pendingFiles.splice(this.pendingFiles.indexOf(file), 1)

		if (resolve) resolve()

	}

	/**
	 * General onProgress callback
	 * @private
	 */
	onProgress() {
		let loaded = 0
		let total = 0
		this.pendingFiles.forEach(file => {
			if (file.loaded && file.total) {
				loaded += file.loaded
				total += file.total
			}
		})

		var evt = new CustomEvent("loadProgress", { detail: loaded / total });
		window.dispatchEvent(evt);

		this.loaded = loaded
		this.total = total
		this.progress = loaded / total
	}

	/**
	 * Load a file
	 * @param {{name: string, path: string}|string}
	 * @param {[string]} inheritanceGroup The list of group which scope the current group
	 */
	addFile(file, inheritanceGroup = []) {
		const finalFile = file.name ? file : { name: file, path: file }

		let finalPath = ''
		inheritanceGroup.forEach(group => (finalPath += group.base ? group.base : ''))
		finalPath += finalFile.path

		const groups = inheritanceGroup.map(group => {
			return group.name
		})

		this.pendingFiles.push({
			name: finalFile.name,
			path: finalPath,
			groups,
			status: FileLoader.PENDING,
			loading: {
				progress: 0,
				total: null,
				loaded: 0
			}
		})
	}

	/**
	 * Load a group
	 * @param {{name: string, base: string, files: [], groups: []}} group Represent the group data
	 * @param {[group]} inheritanceGroup The list of group which scope the current group
	 */
	addGroup({ name = null, base = '', files = [], groups = [] } = {}, inheritanceGroup = []) {
		if (!name) {
			return null
		}

		files.forEach(file => {
			this.addFile(file, [...inheritanceGroup, arguments[0]])
		})

		groups.forEach(group => {
			this.addGroup(group, [...inheritanceGroup, arguments[0]])
		})

		if (!this.groups.includes(name)) {
			this.groups.push(name)
		}
	}

	/**
	 * Load a group by his name
	 * @param {string} name
	 */
	loadGroup(name) {
		const promises = []

		this.pendingFiles.forEach(file => {
			if (file.groups.includes(name)) {
				promises.push(this.loadFile(file))
			}
		})

		return Promise.all(promises)
	}

	/**
	 * Load a file
	 * @param {{name: string, path: string, group: []}} file
	 */
	loadFile(file) {
		return new Promise((resolve, reject) => {
			let loader = null
			rules.some(rule => {
				if (file.path.match(rule.test)) {
					// console.log(file.path)
					loader = rule.loader
					// console.log( rule.loader )
					return true
				}
				return false
			})

			if (loader === null) {
				console.warn(`Loader: The type "${file.type}" is not supported`)
				return null
			}

			loader.load(
				file.path,
				(data, data2) => {
					this.onLoad(file, data, resolve)
					// console.log(file)
				},
				xhr => {
					file.loaded = xhr.loaded
					file.total = xhr.total
					this.onProgress(file)
				},
				e => {
					console.log(e)
					throw new Error(`${file.path} does not exist !`)
				}
			)
		})
	}

}

FileLoader.LOADED = 1
FileLoader.PENDING = 2
FileLoader.ERROR = 3

export default new FileLoader()
