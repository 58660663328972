import {

	DirectionalLight,
	Fog,
	Vector3,
	Mesh,
	Quaternion,
	Color,
	PlaneBufferGeometry,

} from 'three'

import { TweenLite } from 'gsap/all'


import Scene from '../abstractions/Scene'
import DebugCamera from '../components/cameras/DebugCamera'
import DebugStore from '../../store/DebugStore'
import Character from '../components/character/Character'
import Assets from '../controllers/Assets'
import AppStore from '../../store/AppStore'

const PI2 = Math.PI * 2
const white = new Color(1, 1, 1)

export default class IntroScene extends Scene {

	constructor() {
		super()

		/// #if DEVELOPMENT
		this.devTools()
		/// #endif
	}

	init() {
		this.base.fog = (new Fog(0xBBA883, 35, 60))
		this.base.environment = Assets.textures.envmap
		this.base.background = new Color(0xBBA883)
		const character = new Character({
			id: 'player-' + (1),
			model: 'character1',
			position: new Vector3(0, -2.7, 0)
		})

		this.players = [
			this.addComponent(character)
		]

		for (let i = 0; i < this.players.length; i++) {
			this.players[i].base.scale.multiplyScalar(1.3)
		}

		const mat = Assets.materials.connection.clone()
		mat.aoMap = null

		const plane = new Mesh(new PlaneBufferGeometry(100, 100), mat)
		let count = -1
		for (let i = 0; i < plane.geometry.attributes.uv.array.length; i++) {
			count += 1
			if (!count) {
				plane.geometry.attributes.uv.array[i] = 0.2
			} else {
				plane.geometry.attributes.uv.array[i] = 0.3
			}

			if (count === 1) {
				count = -1
			}
		}

		plane.geometry.attributes.uv.needsUpdate = true
		// console.log(plane.geometry.attributes.uv.array[0] = 0.2)
		plane.rotation.x = -Math.PI / 2
		plane.position.y = -4
		this.add(plane)

		this.enter()
		this.defaultCamera.cam.position.z = 18
		this.defaultCamera.cam.position.y = 0
		this.defaultCamera.cam.rotation.set(0, 0, 0)

		this.defaultCamera.cam.lookAt(new Vector3(0, 2, 0))

		this.initCamPos = new Vector3(0, 10, 30)

		TweenLite.from(this.defaultCamera.cam.position, 3, { x: this.initCamPos.x, z: this.initCamPos.z, y: this.initCamPos.y, })

		this.initLights()
		this.bind()
	}

	bind() {
	}

	unbind() {
	}


	initLights() {
		// const light = new AmbientLight( 0xFFFFFF, 100. )
		// this.add( light )

		const directionalLight = new DirectionalLight(0xFFFFFF, 3)
		this.add(directionalLight)

		directionalLight.position.y = 2
		directionalLight.position.z = 1
		// directionalLight.position.x = 0.2
		// this.add( light );
	}

	enter() {
		super.enter()
	}

	destroy() {
		// console.log('default destroyeds')
		/// #if DEVELOPMENT
		this.removeComponent(this.debugCamera)
		DebugStore.debugCamera.unsubscribe(this.toggleOrbitControl, this)
		this.gridHelp = null
		this.debugCamera = null
		/// #endif
		this.unbind()
		super.destroy()
	}

	update(dt) {
		super.update(dt)
	}

	/// #if DEVELOPMENT
	devTools() {
		this.debugCamera = this.addComponent(DebugCamera.get())
		DebugStore.debugCamera.subscribe(this.toggleOrbitControl, this)
		this.toggleOrbitControl(DebugStore.debugCamera.current)
	}

	toggleDebug(v) {
		this.gridHelp.visible = v
	}

	toggleOrbitControl(v) {
		console.log(this.debugCamera)
		this.debugCamera.base.position.copy(new Vector3(0, 0, 5))
		this.debugCamera.base.quaternion.copy(new Quaternion())

		if (v) this.useCamera(this.debugCamera)
		else this.previousCamera && this.useCamera(this.previousCamera)
	}
	/// #endif

}

