<template>
  <div class="start u-wrapper-panel u-row u-center u-middle">
    <div class="u-w4of12 u-w10of12--sm u-relative">
      <img class="u-block u-full-width" src="/images/background.png">
      <div class="start__wrapper u-wrapper-panel u-row u-center u-middle u-row u-pad-xl t-h2">
        Prépare toi à être mis à l’épreuve sur le programme de ce candidat
      </div>
    </div>
  </div>
</template>

<style lang="stylus">
.start
  &__wrapper
    padding 15%
</style>