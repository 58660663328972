const files = {}
const groups = {}

function add( fileID, file, groupID ) {
	files[ fileID ] = file

	if ( ! groupID ) return

	if ( ! groups[ groupID ] ) groups[ groupID ] = {}
	groups[ groupID ][ fileID ] = file
}

function get( id, search ) {
	if ( files[ id ] ) return files[ id ]
	else if ( groups[ id ] ) return groups[ id ]

	if ( ! search ) return

	const results = {}

	for ( const fileID in files )
		fileID.match( id ) && ( results[ fileID ] = files[ fileID ] )

	const keys = Object.keys( results )
	return keys.length > 1 ? results : results[ keys[ 0 ] ]
}

export default {
	add,
	get
}
