<template>
  <div class="popin u-wrapper-panel u-row u-center u-middle">
    <div class="u-w5of12 u-w10of12--sm u-relative">
      <div class="popin__wrapper u-relative u-radius u-overflow-hidden">
        <div class="popin__content popin__fader u-relative">
          <div class="t-h2 u-blue u-relative">
            Bienvenue dans le jeu qui t’entraine à connaitre les programmes des candidats 
          </div>
          <div class="u-radius u-bg-white u-pad-md u-marg-t-md">
            <div class="t-text u-red u-relative">
              Ici tu es dans le QG de campagne, tu peux clicker sur chaque candidat.e pour en apprendre plus sur leurs programmes avant de te lancer dans la course à l’Elysée 
            </div>
          </div>
          <div class="u-row u-marg-t-sm u-evenly">
            <app-button class="u-w8of12--sm" @click="$emit('close')">
              J'ai compris
            </app-button>
          </div>
        </div>
      </div>
      <div class="popin__picto popin__fader u-round u-box-1by1 u-bg-blue">
        <div class="popin__picto__wrapper u-wrapper-panel">
          <svg class="u-fill-white u-full-width u-full-height" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#exclamation"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>