/* eslint-disable */
import DebugStore from '../../store/DebugStore';

/// #if DEVELOPMENT
import * as dat from 'dat.gui';
/// #endif
/* eslint-enable */

let DatGUI = false

/// #if DEVELOPMENT
DatGUI = DebugStore.useGui.current && dat.GUI
/// #endif

let gui

const noop = function () { }
const mockField = { name: noop }


function init() {
	gui = DatGUI ? new DatGUI() : false
	// if ( ! DebugStore.openGui.current ) gui.close()
	const p = gui.domElement.parentNode
	p.classList.add('__datgui')
	p.style.zIndex = 5000
	p.style.top = '5px'
	p.style.right = '-10px'
	p.style.pointerEvents = 'visible'

	document.body.appendChild(p)
}

function add(...params) {
	if (!DatGUI) return mockField
	if (!gui) init()
	return gui.add(...params)
}

function remove(...params) {
	if (!DatGUI) return mockField
	if (!gui) init()
	return gui.remove(...params)
}

const curryStore = ctx => (...params) => {
	if (!DatGUI) return mockField
	if (!gui) init()
	if (!ctx) ctx = gui

	const args = [...params]
	const property = args.shift()

	const obj = {}
	obj[property] = Store[property].get()

	const field = ctx.add(obj, property, ...args)
	field.onChange(v => Store[property].set(v))

	return field
}

const curryUniform = ctx => (...params) => {
	if (!DatGUI) return mockField
	if (!gui) init()
	if (!ctx) ctx = gui

	const args = [...params]
	const material = args.shift()
	const uname = args.shift()
	const field = ctx.add(material.uniforms[uname], 'value', ...args)
	field.name(uname)
	field.onChange(v => {
		material.uniforms[uname].value = v
		material.uniforms[uname].needsUpdate = true
	})

	return field
}

function addColor(...params) {
	if (!DatGUI) return mockField
	if (!gui) init()
	return gui.addColor(...params)
}

function folder(name) {
	if (!DatGUI) return { add: noop, open: noop, close: noop, remove: noop, destroy: noop }
	if (!gui) init()
	const folder = gui.addFolder(name)
	folder.addUniform = curryUniform(folder)
	folder.addStore = curryStore(folder)

	folder.destroy = () => {
		folder.close()
		delete gui.__folders[folder.name]
		folder.__controllers.forEach(c => folder.remove(c))
		folder.domElement.parentNode.parentNode.removeChild(folder.domElement.parentNode)
		folder[name] = undefined
		gui.onResize()
	}

	return folder
}

export default {
	add,
	remove,
	addColor,
	addUniform: curryUniform(),
	addStore: curryStore(),
	folder,
	getInstance: () => gui
}
