<template>
  <component :is="to.name ? 'router-link' : url !== '' ? 'a' : 'div'" :to="to" :href="url" :target="isUrlAbsolute ? '_blank' : ''" class="button-round u-block" :class="{'is-big': big}" >
    <div class="u-box-1by1 u-bg-white u-shadow u-round u-cursor-pointer">
      <div class="button-round__wrapper u-wrapper-panel">
        <div class="u-full-width u-full-height u-relative">
          <slot class="u-fit-contain" />
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'

.button-round
  width 60px

  +mq($until: 'tablet')
    width 50px
  
  &.is-big
    width 80px

    +mq($until: 'tablet')
      width 60px

  &.is-big &__wrapper
    padding 27%
  
  
  &__wrapper
    padding 35%

    +mq($until: 'tablet')
      padding 30%
</style>