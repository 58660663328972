import {
	Vector3,
	InstancedMesh,
	Color,
	DynamicDrawUsage,
	Matrix4,
	MeshStandardMaterial,
	MeshPhysicalMaterial,
	MeshMatcapMaterial,
	BoxBufferGeometry,
	InstancedBufferAttribute,
	Euler,
	Quaternion,
	Texture,
	sRGBEncoding
} from 'three'

import GameObject from '../../abstractions/GameObject'
import { Colors } from '../../../utils/styleguide'
import Assets from '../../controllers/Assets'
import Files from '../../../managers/assetsManager/Files'
import FloorMaterial from '../../materials/FloorMaterial/FloorMaterial'
import { gameConfig } from '../../config/gameConfig'
import { MeshPhongMaterial } from 'three'
import gui from '../../utils/gui'
import DebugStore from '../../../store/DebugStore'

const COUNT = 3
let material
let zposArr = []
const geoPool = []

export default class Floor extends GameObject {

	constructor(props = {}) {
		super(props)

		if (!material) material = new MeshStandardMaterial({
			roughness: 1, metalness: 0.72,
		})


		// const colors = [new Color(0x0153862), new Color(0xD9C4C4), new Color(0xDB4D5B)]
		// const colors = [new Color(0x153862), new Color(0xD9C4C4), new Color(0xB51616)]
		const colors = [new Color(0x275adb), new Color(0xD9C4C4), new Color(0xd03232)]


		// const instanceColors = [];
		// let colorIndex = 0
		// for (let i = 0; i < COUNT; i++) {

		// 	instanceColors.push(colors[colorIndex].r);
		// 	instanceColors.push(colors[colorIndex].g);
		// 	instanceColors.push(colors[colorIndex].b);

		// 	colorIndex++
		// 	// if ((i - 1) % 2 == 0) colorIndex++
		// 	if (colorIndex > 2) colorIndex = 0
		// }

		const colorParsChunk = [
			// 'attribute vec3 instanceColor;',
			// 'varying vec3 vInstanceColor;',
			'vec3 displace(vec3 v) {',
			'float dist = distance(v.z, cameraPosition.z);',
			'if( dist < 0.0) { dist = 0.0; }',
			'float addY = dist * dist;',
			`return vec3(v.x, v.y - addY * (1. * ${gameConfig.bendY.toString()}), v.z);`,
			'}',
			'#include <common>'
		].join('\n');

		const instanceColorChunk = [
			'#include <worldpos_vertex>',
			// '\tvInstanceColor = instanceColor;',
			'transformed = worldPosition.xyz;',
			'transformed = displace(transformed);',
			'	float e = -.01;',
			'vec3 dA = e * normalize(cross(normal.yzx, normal));',
			'vec3 dB = e * normalize(cross(dA, normal));',
			'vec3 pA = worldPosition.xyz + dA ;',
			'vec3 pB = worldPosition.xyz + dB ;',
			'vec3 hA = displace(pA);',
			'vec3 hB =  displace(pB);',
			'vec4 testNormal = vec4(cross(hA-transformed, hB-transformed), 1.);',
			'vNormal = normalize(normalMatrix * testNormal.xyz);',
			// vNormal = vNormal; ',
			'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
		].join('\n');

		const fragmentParsChunk = [
			// 'varying vec3 vInstanceColor;',
			// 'uniform sampler2D matcap;',
			'#include <common>'
		].join('\n');

		const colorChunk = [
			// 'vec4 diffuseColor = vec4( vNormal, opacity );'
			// 'vec4 diffuseColor = vec4( diffuse * vInstanceColor, opacity );'
		].join('\n');

		const matCapChunk = [
			'#include <normal_fragment_maps>',
			'vec3 viewDir = normalize( vViewPosition );',
			'vec3 x = normalize( vec3( viewDir.z, 0.0, -viewDir.x ) );',
			'vec3 y = cross( viewDir, x );',
			'vec2 uv = vec2( dot( x, normal ), dot( y, normal ) ) * 0.495 + 0.5;',
			'vec4 matcapColor = texture2D( matcap, uv );',
			'diffuseColor = diffuseColor * matcapColor;',
		].join('\n');

		material.onBeforeCompile = function (shader) {

			shader.vertexShader = shader.vertexShader
				.replace('#include <common>', colorParsChunk)
				.replace('#include <worldpos_vertex>', instanceColorChunk);

			shader.fragmentShader = shader.fragmentShader
				.replace('#include <common>', fragmentParsChunk)
			// .replace('#include <normal_fragment_maps>', matCapChunk)
			// .replace('vec4 diffuseColor = vec4( diffuse, opacity );', colorChunk);


			// shader.uniforms = { ...shader.uniforms, matcap: { value: Assets. } }
			// shader.uniforms
			// console.log(shader.uniforms);

			// console.log(shader.vertexShader);
			// console.log(shader.fragmentShader);

		};
		// console.log('MATTT', material)
		// material.uniforms.matcap.needUpdate = true

		const blueprint = new BoxBufferGeometry(5.68, 1, 70, 1, 1, 100)
		// blueprint.setAttribute('instanceColor', new InstancedBufferAttribute(new Float32Array(instanceColors), 3));
		blueprint.computeVertexNormals();

		this.base = new InstancedMesh(blueprint, material, COUNT)
		let color = new Color()
		this.base.setColorAt(0, color.set(colors[0]));
		this.base.setColorAt(1, color.set(colors[1]));
		this.base.setColorAt(2, color.set(colors[2]));
		this.base.instanceColor.needsUpdate = true;
		if (DebugStore.useGui.current) {
			let folderRoad = gui.folder('Road')
			var params = {
				road1: 0xffffff,
				road2: 0xffffff,
				road3: 0xffffff,
			};
			folderRoad.addColor(params, 'road1').name('Road 1')
				.listen()
				.onChange(() => {
					let color = new Color()
					color.set(params.road1)
					this.base.setColorAt(0, color);
					this.base.instanceColor.needsUpdate = true;
				});
			folderRoad.addColor(params, 'road2').name('Road 2')
				.listen()
				.onChange(() => {
					let color = new Color()
					color.set(params.road2)
					this.base.setColorAt(1, color);
					this.base.instanceColor.needsUpdate = true;
				});

			folderRoad.addColor(params, 'road3').name('Road 3')
				.listen()
				.onChange(() => {
					let color = new Color()
					color.set(params.road3)
					this.base.setColorAt(2, color);
					this.base.instanceColor.needsUpdate = true;
				});
		}


		this.base.receiveShadow = true
		this.base.instanceMatrix.setUsage(DynamicDrawUsage);
		this.base.frustumCulled = false
		let xPos = 6
		for (let i = 0; i < COUNT; i++) {
			let matrix = new Matrix4();

			let position = new Vector3();
			let rotation = new Euler();
			let quaternion = new Quaternion();
			let scale = new Vector3();

			position.x = xPos
			position.y = 0
			position.z = 0

			xPos -= 6

			quaternion.setFromEuler(rotation);

			scale.x = scale.y = scale.z = 1;

			matrix.compose(position, quaternion, scale);

			this.base.setMatrixAt(i, matrix);
		}

		this.bind()

		// gui.addMaterial('standard_material', material);
	}

	bind() {
	}

	unbind() {
	}

	update(dt) {
		super.update(dt)

		// for (let i = 0; i < COUNT; i++) {
		// 	let matrix = new Matrix4();

		// 	let position = new Vector3();
		// 	let rotation = new THREE.Euler();
		// 	let quaternion = new THREE.Quaternion();
		// 	let scale = new THREE.Vector3();
		// 	zposArr[i] = zposArr[i] - .4
		// 	position.x = i % 2 ? -8.5 : 8.5
		// 	position.y = 0
		// 	position.z = zposArr[i]

		// 	rotation.x = 0
		// 	rotation.z = 1.5;

		// 	if (zposArr[i] < -20) {
		// 		zposArr[i] = 48.85
		// 	}


		// 	quaternion.setFromEuler(rotation);

		// 	scale.x = scale.y = scale.z = .275;

		// 	matrix.compose(position, quaternion, scale);

		// 	this.base.setMatrixAt(i, matrix);
		// }


		// this.base.instanceMatrix.needsUpdate = true;
	}

	beforeDestroy() {
		geoPool.push(this.geo)
		this.data = this.geo = null
		this.unbind()
	}

	destroy() {
		this.base.geometry.dispose()
		super.destroy()
	}

}


