<template>
  <div class="transition-screen u-wrapper-panel u-row u-pointer-none">
    <div class="transition-screen__part transition-screen__part--0 u-w4of12 u-full-height u-bg-blue" />
    <div class="transition-screen__part transition-screen__part--1 u-w4of12 u-full-height u-bg-white" />
    <div class="transition-screen__part transition-screen__part--2 u-w4of12 u-full-height u-bg-red" />
  </div>
</template>

<style lang="stylus">
@import '~@/styles/settings/easings'
@import '~@/styles/settings/variables'

.transition-screen
  &__part
    transform translateZ(0)
    transition transform .6s ease-in-out-quad
    pointer-events all

    for index in 0 1 2
      &--{index}
        transition-delay index*.2s

  &.custom-enter-from &__part
    transform translate3d(0, -100%, 0)

  &.custom-leave-to &__part
    transform translate3d(0, 100%, 0)

</style>