const Colors = {
  blue: 0x5882D6,
  darkBLue: 0x003E79,
  red: 0xE86F7B,
  white: 0xF6F6F6,
}

export {
  Colors
}

