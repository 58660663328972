<template>
  <div class="popin u-wrapper-panel u-row u-center u-middle">
    <div class="u-w4of12 u-w10of12--sm u-relative">
      <div class="popin__wrapper u-relative u-radius u-overflow-hidden">
        <div class="popin__content popin__fader u-relative">
          <div clss="u-marg-b-md">
            <div v-if="candidates.length === 1" class="t-h2 u-blue">
              Votre campagne débute avec
            </div>
            <div v-else class="t-h1 u-blue">
              Passation !!!
            </div>
          </div>
          <div
            v-for="(candidate, i) in candidates"
            class="u-full-width u-radius u-row u-marg-t-md u-white"
            :style="{backgroundColor: candidate.color}"
          >
            <div class="u-w3of12 u-relative">
              <app-image v-if="candidate.game.image.length" class="u-wrapper-panel u-fit-contain" :id="candidate.game.image[0].id" :width="100" />
            </div>
            <div class="t-h1 t-h2--sm u-pad-y-md u-pad-y-sm--sm" :class="candidates.length === 1 ? 'u-w9of12' : 'u-w7of12'">
              {{ candidate.shortName }}
            </div>
            <div v-if="candidates.length !== 1" class="u-w2of12 u-pad-r-md u-flex">
              <svg
                class="popin-candidate__svg u-fill-white"
                preserveAspectRatio="xMidYMid meet"
              >
                <use :xlink:href="`#arrow-${i === 0 ? 'right--small' : 'left--small'}`"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="popin__picto popin__fader u-round u-box-1by1 u-bg-red">
        <div class="popin__picto__wrapper u-wrapper-panel">
          <svg class="u-fill-white u-full-width u-full-height" preserveAspectRatio="xMidYMid meet">
            <use :xlink:href="'#exclamation'"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    candidates: {
      type: Array,
      default: () => { return [] }
    },
    pictoColor: {
      type: String,
      default: 'blue'
    },
    cta: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
.popin-candidate
  &__svg
    height 60px
    width 60px
    align-self center
</style>