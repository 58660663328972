import { Readable } from './readable'

/**
 * Inherits from [Readable](#Readable) - See its API for `get` method
 *
 * :warning: `dispatch()` method from Readable is removed
 * @param {*} initialValue Initial value
 * @augments Readable
 * @return {Readable} Readable instance
 */
class Writeable extends Readable {

	set( value, force ) {
		if ( ! force && this.current === value ) return
		const previous = this.current

		this.current = value
		let node = this._first
		while ( node ) {
			node.fn.call( node.ctx, this.current, previous )
			node.once && this.unsubscribe( node )
			node = node.next
		}
	}

	update( cb, force ) {
		this.set( cb( this.current ), force )
	}

}

function writeable( initialValue ) {
	return new Writeable( initialValue )
}

export { Writeable }
export default writeable
