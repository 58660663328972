import { createApp, mixin } from 'vue'
import { createRouter } from 'vue-router'

import { store } from '@/store'
import { router } from './router'

import { emitter } from '@/utils/emitter'

import Local from '@/plugins/Local'
import Device from '@/plugins/Device'
import Hub from '@/plugins/Hub'
import Offset from '@/plugins/Offset'
import Dom3d from '@/plugins/Dom3d'

import messages from '@/locales'

import AppImage from '@/components/common/AppImage'
import AppButton from '@/components/common/AppButton'

import extendedComponent from '@/mixins/extendedComponent'

import App from './App.vue'

class VueArtwork {
  constructor () {
    this.app = createApp(App)

    // router
    this.router = createRouter(router)
    this.app.use(this.router)

    // VueX
    this.app.use(store)

    // Mixin
    this.app.mixin(extendedComponent)

    // Handmade Plugins
    this.app.use(Local)
    this.app.use(Device)
    this.app.use(Hub)
    this.app.use(Offset)
    this.app.use(Dom3d)

    this.app.component('app-image', AppImage)
    this.app.component('app-button', AppButton)

    this.app.mount('#app')

    this.router.beforeEach(this.beforeGuard)
  }

  beforeGuard (to, from, next) {
    if (!from.name) {
      // debug
      if (to.query.skip || to.name === 'Homepage') {
        next()
        return
      }
      // redirect to homepage
      else {
        next({name: 'Homepage'})
        return
      }
    }

    emitter.emit('transition:on')

    setTimeout(() => {
      next()
      emitter.emit('transition:off')
    }, 2000)
  }
}

new VueArtwork()
