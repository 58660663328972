import { api } from '@/store/interfaces/apiCraft'
import { scoring, second } from '@/assets/data'

export default {
  namespaced: true,

  state: {
    infos: [],
    currentCandidate: {},
    randomCandidates: [],
    loaded: false
  },

  getters: {
    infos: state => {
      return state.infos
    },
    candidate: state => {
      return state.infos
    },
    infosBySlug: state => slug => {
      if (state.infos.length) {
        return state.infos.find(info => info.slug === slug)
      }

      return []
    },
    nextCandidate: state => condidate => {
      const index = (condidate.index + 1) % state.infos.length
      return state.infos[index]
    },
    prevCandidate: state => condidate => {
      const index = condidate.index === 0 ? state.infos.length - 1 : condidate.index - 1
      return state.infos[index]
    },
    randomCandidates: state => {
      return state.randomCandidates
    },
    score: state => {
      return state.infos.reduce((accumulator, current) => {
        return accumulator += current.score
      }, 0)
    },
    loaded: state => {
      return state.loaded
    },
  },

  actions: {
    infoCandidats ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = api.getInfosCandidats()
        // cache.then(res => {
        //   if (res !== null) {
        //     commit('entries', res)
        //     commit('loaded', true)
        //   }
        // })

        request.then(async res => {
          await dispatch('formatInfoCandidats', res)
          resolve()
        })
      })
    },

    formatInfoCandidats ({ commit, state }, candidats) {
      candidats.forEach((candidat, index) => {
        candidat.index = index
        candidat.shortName = candidat.title
        candidat.name = candidat.game[0].name
        candidat.game = candidat.game[0]
        candidat.questions = shuffle(candidat.game.stQuestions)
        candidat.picto = candidat.game.image[0]
        candidat.score = 0
        candidat.isSecond = second.includes(candidat.slug)
      })

      commit('infos', candidats)
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    infos (state, payload) {
      state.infos = payload
      state.loaded = payload
    },
    currentCandidate (state, payload) {
      state.currentCandidate = payload
    },
    answer (state) {
      state.currentCandidate.score += scoring.right
    },
    answerFast (state) {
      state.currentCandidate.score += scoring.fast
    },
    bonus (state) {
      state.currentCandidate.score += scoring.bonus
    },
    malus (state) {
      state.currentCandidate.score += scoring.malus
    },
    wrong (state) {
      state.currentCandidate.score += scoring.wrong
    },
    randomCandidates (state, limit) {
      state.infos.forEach(candidate => {
        candidate.score = 0
      })
      const randoms = getRandomNumbers(state.infos.length, limit)
      state.randomCandidates = randoms.map(rand => {
        return state.infos[rand]
      })
    },
    randomSecondCandidates (state) {
      state.infos.forEach(candidate => {
        candidate.score = 0
      })

      if (Math.random() > .5) {
        state.randomCandidates = state.infos.filter(c => c.isSecond)
      }
      else {
        state.randomCandidates = state.infos.filter(c => c.isSecond).reverse()
      }


    }
  }
}

function shuffle (array) {
  let currentIndex = array.length, randomIndex

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}


function getRandomNumbers (range, limit, selected = []) {
  if (range < limit) {
    return []
  }

  const nbr = Math.floor(Math.random() * range)
  if (!selected.includes(nbr)) {
    selected.push(nbr)
  }

  if (selected.length === limit) {
    return selected
  }

  return getRandomNumbers(range, limit, selected)
}