/**
 * Pipeline Controller
 * - Initialize the WebGL Renderer
 * - Handle the Update and Render lifecycle
 */

import {
	WebGLRenderer,
	BasicShadowMap,
	Vector2,
	ACESFilmicToneMapping,
} from 'three'
import { clamp } from '@internet/maths'

import Pipeline from '../pipeline/Pipeline'

import { updateTimers } from '../utils/timers'

import CanvasStore from '../../store/CanvasStore'
import AppStore from '../../store/AppStore'
// import { Stats, ThreeInfos } from '../utils/Stats'
import GameStore from '../../store/GameStore'
import { PCFSoftShadowMap } from 'three'

/// #if DEVELOPMENT
// const stats = new ThreeInfos()
/// #endif

let initialized = false
let renderer

// FIX FOR SCREENS > 60HZ
const dtThreshold = 16
const dtAccum = 0
// ENDFIX

// Init the renderer
function init(opts = {}) {
	if (initialized) return
	initialized = true

	renderer = new WebGLRenderer({
		antialias: true,
		alpha: false,
		depth: true,
		stencil: false,
		preserveDrawingBuffer: false,
		powerPreference: 'high-performance',
		// premultipliedAlpha: false,
	})
	console.log(renderer.info)
	/// #if DEVELOPMENT
	// stats.add({

	// 	'programs': { object: renderer.info.programs, key: 'length' },
	// 	'geometries': { object: renderer.info.memory, key: 'geometries' },
	// 	'textures': { object: renderer.info.memory, key: 'textures' },
	// 	'drawcalls': { object: renderer.info.render, key: 'calls' },
	// 	'triangles': { object: renderer.info.render, key: 'triangles' }

	// })
	/// #endif

	// console.log(renderer)

	// renderer.setClearColor(CanvasStore.bgColor.get())
	// renderer.autoClear = false
	// renderer.info.autoReset = false

	renderer.shadowMap.enabled = CanvasStore.enableShadows.get()
	renderer.shadowMap.type = PCFSoftShadowMap

	renderer.toneMapping = ACESFilmicToneMapping
	renderer.toneMappingExposure = 1.4;

	(opts.parent || document.body).appendChild(renderer.domElement)


	// Listeners for the canvas size
	AppStore.viewportSize.subscribe(resize)
	CanvasStore.canvasPixelRatio.subscribe(canvasPixelRatioChange)
	canvasPixelRatioChange(CanvasStore.canvasPixelRatio.get())
	resize(AppStore.viewportSize.get())

	// setup rendering Pipeline
	Pipeline.init({ renderer })
	// Pipeline.resize()
}


// A game tick. Consist of update components then rendering the scene
function tick(dt) {
	if (dt === undefined) dt = 16.6667
	const clampedDt = clamp(dt, 1, 130)
	renderer.info.reset()

	update(clampedDt)

	render(clampedDt)


	/// #if DEVELOPMENT

	// stats.update()

	/// #endif
}

function appLoaded() {
	Pipeline.appLoaded()
}


function update(dt) {
	updateTimers(dt)
	Pipeline.update(dt)
}

function render(dt) {
	Pipeline.render(dt)
}



function updateDrawingBufferSize() {
	// const vec2 = Vector2.get()
	// renderer.getDrawingBufferSize(vec2)
	// Vector2.release(vec2)

	// CanvasStore.drawingBufferSize.update(dbs => {
	// 	dbs[0] = vec2.x
	// 	dbs[1] = vec2.y
	// 	return dbs
	// }, true)
}

// Change Canvas pixel ratio - retrigger Pipeline resize
function canvasPixelRatioChange(pixelRatio) {
	renderer.setPixelRatio(pixelRatio)
	resize()
}

// Resize canvas when app is resizing
// Also resize rendering Pipeline
function resize() {
	const newSize = AppStore.viewportSize.current

	const w = newSize[0]
	const h = newSize[1]
	renderer.setSize(w, h)
	updateDrawingBufferSize()

	CanvasStore.canvasSize.set([w, h], true)

	if (Pipeline.isReady()) Pipeline.resize()
}

export default {
	init,
	appLoaded,
	tick,
	getRenderer: () => renderer,
	getDomElement: () => renderer.domElement
}
