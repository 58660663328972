
import { Vector3 } from 'three'

import GameObject from '../../abstractions/GameObject'
import Files from '../../../managers/assetsManager/Files'
import { Box3 } from 'three';
import { gameConfig } from '../../config/gameConfig';
import { Color } from 'three';

export default class Logo extends GameObject {

  init({ position, rotation, scale } = props) {
    const gameAssets = Files.get('game')
    this.name = "coin"
    this.mesh = gameAssets['logo'].scene.children[0].clone()

    this.position = position || new Vector3(2, 8, 5);
    this.scale = scale || new Vector3(.3, .3, .3);
    this.rotation = rotation || new Vector3(0, 3, 0);

    const material = this.mesh.material

    // material.metalness = .3
    // const material = new MeshPhysicalMaterial({ color: new Color(Colors.darkBLue), clearcoat: 1.5, specularIntensity: 1 })
    const colorParsChunk = [
      'vec3 displace(vec3 v) {',
      'float dist = distance(v.z, cameraPosition.z);',
      'if( dist < 0.0) { dist = 0.0; }',
      'float addY = dist * dist;',
      `return vec3(v.x, v.y - addY * (1. * ${gameConfig.bendY.toString()}), v.z);`,
      '}',
      '#include <common>'
    ].join('\n');

    const instanceColorChunk = [
      '#include <worldpos_vertex>',
      'transformed = worldPosition.xyz;',
      'transformed = displace(transformed);',
      '	float e = -.01;',
      'vec3 dA = e * normalize(cross(normal.yzx, normal));',
      'vec3 dB = e * normalize(cross(dA, normal));',
      'vec3 pA = worldPosition.xyz + dA ;',
      'vec3 pB = worldPosition.xyz + dB ;',
      'vec3 hA = displace(pA);',
      'vec3 hB =  displace(pB);',
      'vec4 testNormal = vec4(cross(hA-transformed, hB-transformed), 1.);',
      'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
    ].join('\n');
    const colorChunk = [
      'vec4 diffuseColor = vec4( diffuse , opacity );'
      // 'vec4 diffuseColor = vec4( vNormal, opacity );'
    ].join('\n');

    material.onBeforeCompile = function (shader) {

      shader.vertexShader = shader.vertexShader
        .replace('#include <common>', colorParsChunk)
        .replace('#include <worldpos_vertex>', instanceColorChunk);

      shader.fragmentShader = shader.fragmentShader
        // .replace('#include <common>', fragmentParsChunk)
        .replace('vec4 diffuseColor = vec4( diffuse, opacity );', colorChunk);

      //console.log( shader.uniforms );
      //console.log( shader.vertexShader );
      //console.log( shader.fragmentShader );

    };

    this.base = this.mesh
    this.base.scale.set(this.scale.x, this.scale.y, this.scale.z)
    this.base.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
    this.base.position.set(this.position.x, this.position.y, this.position.z)

    this.base.castShadow = true
    this.base.receiveShadow = true
  }


  destroy() {
    this.base.geometry.dispose()
    super.destroy()

  }

  sceneUpdate() {
  }

  update(dt) {
    super.update(dt)

    this.base.position.z -= (gameConfig.logoSpeed * gameConfig.globalSpeed)

  }

}

