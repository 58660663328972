

import {
  Vector3,
  InstancedMesh,
  DynamicDrawUsage,
  Matrix4,
  Euler,
  Quaternion,
} from 'three'

import GameObject from '../../abstractions/GameObject'

import Files from '../../../managers/assetsManager/Files'
import { gameConfig } from '../../config/gameConfig'
import gsap from 'gsap'

const COUNT = 10
let material
const geoPool = []

export default class SmilingParticles extends GameObject {

  init({ character } = props) {
    const mesh = Files.get('game').emoji_smile.scene.children[0]
    this.geo = mesh.geometry
    this.character = character
    const material = mesh.material

    const colorParsChunk = [
      'vec3 displace(vec3 v) {',
      'float dist = distance(v.z, cameraPosition.z);',
      'if( dist < 0.0) { dist = 0.0; }',
      'float addY = dist * dist;',
      `return vec3(v.x, v.y - addY * (1. * ${gameConfig.bendY.toString()}), v.z);`,
      '}',
      '#include <common>'
    ].join('\n');

    const instanceColorChunk = [
      '#include <worldpos_vertex>',
      // 'transformedNormal = normalMatrix * transformedNormal;',
      'transformed = worldPosition.xyz;',
      'transformed = displace(transformed);',
      '	float e = -.01;',
      'vec3 dA = e * normalize(cross(normal.yzx, normal));',
      'vec3 dB = e * normalize(cross(dA, normal));',
      'vec3 pA = worldPosition.xyz + dA ;',
      'vec3 pB = worldPosition.xyz + dB ;',
      'vec3 hA = displace(pA);',
      'vec3 hB =  displace(pB);',
      'vec4 testNormal = vec4(cross(hA-transformed, hB-transformed), 1.);',
      'vNormal = normalize(normalMatrix * testNormal.xyz);',
      'vNormal = normalize(transformedNormal);',
      'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
    ].join('\n');

    const fragmentParsChunk = [
      'varying vec3 vInstanceColor;',
      '#include <common>'
    ].join('\n');

    material.onBeforeCompile = function (shader) {

      shader.vertexShader = shader.vertexShader
        .replace('#include <common>', colorParsChunk)
        .replace('#include <worldpos_vertex>', instanceColorChunk);

      shader.fragmentShader = shader.fragmentShader
        .replace('#include <common>', fragmentParsChunk)

      //console.log( shader.uniforms );
      //console.log( shader.vertexShader );
      //console.log( shader.fragmentShader );

    };

    this.geo.computeVertexNormals();

    this.base = new InstancedMesh(this.geo, material, COUNT)
    this.base.castShadow = true
    // this.base.receiveShadow = true
    this.base.instanceMatrix.setUsage(DynamicDrawUsage);
    this.base.frustumCulled = true
    this.randomXArr = []
    this.randomYArr = []
    this.randomZArr = []
    this.rotationArr = []
    this.randomSpeed = []

    this.scaleAarr = []
    for (let i = 0; i < COUNT; i++) {
      let matrix = new Matrix4();
      let position = new Vector3();
      this.rotation = new Euler();
      this.quaternion = new Quaternion();
      this.scale = new Vector3();

      const randomY = Math.floor(Math.random() * (5 - 1)) + 1;
      const randomX = (((Math.floor(Math.random() * 10) + 1) / 10) + .15) * (Math.round(Math.random()) ? 1 : -1)
      const randomZ = Math.ceil(Math.random() * 2) * (Math.round(Math.random()) ? 1 : -1)
      const randomSpeed = 1 + ((Math.floor(Math.random() * 10) + 1) / 10)

      this.randomXArr.push(randomX)
      this.randomYArr.push(0)
      this.randomZArr.push(randomZ)
      this.randomSpeed.push(randomSpeed)

      position.y = randomY
      position.x = randomX
      position.z = randomZ

      this.rotation.x = .8 - ((Math.floor(Math.random() * 10) + 1) / 10)
      this.rotation.y = 3.5 - ((Math.floor(Math.random() * 10) + 1) / 10)
      this.rotation.z = (Math.floor(Math.random() * 10) + 1) / 10;

      this.quaternion.setFromEuler(this.rotation);

      this.rotationArr.push(this.quaternion)

      this.scaleValue = .5 + ((Math.floor(Math.random() * 10) + 1) / 10);


      this.scale.x = this.scale.y = this.scale.z = 0

      this.scaleAarr.push(this.scale)

      matrix.compose(position, this.quaternion, this.scale);

      this.base.setMatrixAt(i, matrix);
    }
    this.bind()
  }

  bind() {
  }

  unbind() {
  }

  playAnimationGoodAnswer() {
    this.playingGoodAnswer = true

    this.randomXArr = []
    this.randomYArr = []
    this.randomZArr = []
    this.rotationArr = []
    this.randomSpeed = []
    this.scaleAarr = []
    for (let i = 0; i < COUNT; i++) {
      this.rotation = new Euler();
      this.quaternion = new Quaternion();
      this.scale = new Vector3();

      const randomX = (((Math.floor(Math.random() * 10) + 1) / 10) + .15) * (Math.round(Math.random()) ? 1 : -1)
      const randomZ = Math.ceil(Math.random() * 2) * (Math.round(Math.random()) ? 1 : -1)
      const randomSpeed = 1 + ((Math.floor(Math.random() * 10) + 1) / 10)

      this.randomXArr.push(randomX)
      this.randomYArr.push(0)
      this.randomZArr.push(randomZ)
      this.randomSpeed.push(randomSpeed)

      this.rotation.x = .8 - ((Math.floor(Math.random() * 10) + 1) / 10)
      this.rotation.y = 3.5 - ((Math.floor(Math.random() * 10) + 1) / 10)
      this.rotation.z = (Math.floor(Math.random() * 10) + 1) / 10;

      this.quaternion.setFromEuler(this.rotation);

      this.rotationArr.push(this.quaternion)

      this.scaleValue = .5 + ((Math.floor(Math.random() * 10) + 1) / 10);

      // gsap.to(this.scale, { x: this.scaleValue, y: this.scaleValue, z: this.scaleValue, duration: .05 })

      this.scale.x = this.scale.y = this.scale.z = this.scaleValue;

      this.scaleAarr.push(this.scale)
    }
  }

  animationGoodAnswer() {
    for (let i = 0; i < COUNT; i++) {
      let matrix = new Matrix4();

      let position = new Vector3();

      this.randomYArr[i] = this.randomYArr[i] + (.058 * this.randomSpeed[i])

      position.x = this.character.base.position.x + this.randomXArr[i]
      position.y = this.randomYArr[i]
      position.z = this.character.base.position.z + this.randomZArr[i]

      const scale = this.scaleAarr[i]
      const quaternion = this.rotationArr[i]

      if (this.randomYArr[i] > 10) {
        this.scaleAarr[i] = 0
      }

      matrix.compose(position, quaternion, scale);

      this.base.setMatrixAt(i, matrix);
    }
  }

  update(dt) {
    super.update(dt)

    this.animationGoodAnswer()

    this.base.instanceMatrix.needsUpdate = true;
  }

  beforeDestroy() {
    geoPool.push(this.geo)
    this.data = this.geo = null
    this.unbind()
  }

  destroy() {
    if(this.geo) this.geo.dispose()
    super.destroy()
  }

}


