
import { Vector3, } from 'three'

import GameObject from '../../abstractions/GameObject'

import Files from '../../../managers/assetsManager/Files'
import { baseXLeft, baseXRight, getCurrentTiles, getName, setCurrentTilesIndex, tilesToSpawn, tilesZpos } from '../../controllers/SpawnDecor';
import Flag from './Flag';
import StreetLamp from './StreetLamp';
import { gameConfig } from '../../config/gameConfig';
import Car from './Car';
import Bushes from './Bushes';
import Train from './Train';
import Coq from './Coq';
import Panneau from './Panneau';
import Arc from './Arc';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { Mesh } from 'three';
import { MeshBasicMaterial } from 'three';
import { Color } from 'three';
import { DoubleSide } from 'three';
import { Matrix4 } from 'three';
import { Euler } from 'three';
import { Quaternion } from 'three';


export default class Decors extends GameObject {

  init() {
    const gameAssets = Files.get('game')
    this.allTiles = gameAssets['tile'].scene.children[0].children
    this.decorsArr = []
    this.margeBetweenNewtile = 10
    this.tiles = []
    this.toMerge = []
    const START_INDEX = 2
    // const scene = Scenes.get()
    // console.log('SCENNNEe', scene)
    this.generateTiles(1, null, true, START_INDEX)
  }

  generateTiles(nbToGenerate, scene, start, startIndex) {
    this.canGenerate = false
    this.currentTiles = []
    let toGenerate = nbToGenerate || tilesToSpawn
    for (let i = 0; i < toGenerate; i++) {
      setCurrentTilesIndex()
      let tile = getCurrentTiles(this.allTiles, startIndex)

      // let tile = this.allTiles[9]
      // console.log('TILE', tile)
      this.currentTiles.push(tile)

    }


    this.geomFlag = []
    this.geomStreetLamp = []
    this.geomCar = []
    this.geomBushes = []
    this.geomTrain = []
    this.geomCoq = []
    this.geomPanneau = []
    this.geomArc = []

    this.initDecorObject(scene, nbToGenerate, start)

  }

  initDecorObject(scene, nbToGenerate, start) {
    this.currentTiles.forEach((child, tileNb) => {
      const tiles = child.children
      let tempArr = []
      tiles.forEach((tile, index) => {
        tile.children.forEach((position) => {
          const name = getName(position.userData.name).toLowerCase()

          switch (name) {
            case 'drapeau': {
              let flag = this.createFlag(position, index, tileNb, nbToGenerate, start)
              tempArr.push(flag)
              if (scene) scene.addComponent(flag)
            }
              break;
            case 'lampadaire': {
              let streetlamp = this.createStreetLamp(position, index, tileNb, nbToGenerate, start)
              tempArr.push(streetlamp)
              if (scene) scene.addComponent(streetlamp)
            }
              break;
            case 'car': {
              let car = this.createCar(position, index, tileNb, nbToGenerate, start)
              tempArr.push(car)
              if (scene) scene.addComponent(car)
            }
              break;
            case 'buisson': {
              let bushes = this.createBushes(position, index, tileNb, nbToGenerate, start)
              tempArr.push(bushes)
              if (scene) scene.addComponent(bushes)
            }
              break;
            case 'tramway': {
              let train = this.createTrain(position, index, tileNb, nbToGenerate, start)
              tempArr.push(train)
              if (scene) scene.addComponent(train)
            }
              break;
            case 'coq': {
              let coq = this.createCoq(position, index, tileNb, nbToGenerate, start)
              tempArr.push(coq)
              if (scene) scene.addComponent(coq)
            }
              break;
            case 'panneau': {
              let panneau = this.createPanneau(position, index, tileNb, nbToGenerate, start)
              tempArr.push(panneau)
              if (scene) scene.addComponent(panneau)
            }
              break;
            case 'arc': {
              let arc = this.createArc(position, index, tileNb, nbToGenerate, start)
              tempArr.push(arc)
              if (scene) scene.addComponent(arc)
            }
              break;
          }
        })
      })
      this.tiles.push(tempArr)
    })
    // console.log('ALL FLAG GEOM', this.geomFlag, BufferGeometryUtils)
    // const geometry = BufferGeometryUtils.mergeBufferGeometries(this.geomFlag);
    // geometry.computeBoundingSphere();
    // this.test = new Mesh(geometry, new MeshBasicMaterial({ color: new Color('red'), side: DoubleSide }));
    // if (scene) scene.addComponent(mesh);
    // console.log('GEOM FLAG', geometry)
  }

  // addToMege(name, matrix) {
  //   return { name, }

  // }

  createFlag(position, index, tileNb, nbToGenerate, start) {
    let xPos = index === 0 ? baseXLeft : baseXRight
    let flag = new Flag({
      position: new Vector3(xPos += position.position.x, -0.05, position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      rotation: new Euler(position.rotation.x, position.rotation.y, position.rotation.z)
    })

    return flag
  }

  createStreetLamp(position, index, tileNb, nbToGenerate, start) {
    let xPos = index === 0 ? baseXLeft : baseXRight
    let streetlamp = new StreetLamp({
      position: new Vector3(xPos += position.position.x, -.5, position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      rotation: new Euler(position.rotation.x, position.rotation.y, position.rotation.z)
    })

    return streetlamp
  }

  createCar(position, index, tileNb, nbToGenerate, start) {
    let xPos = index === 0 ? baseXLeft : baseXRight
    let car = new Car({
      position: new Vector3(xPos += position.position.x, 0., position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      rotation: new Euler(position.rotation.x, position.rotation.y, position.rotation.z)
    })

    return car
  }

  createBushes(position, index, tileNb, nbToGenerate, start) {
    let xPos = index === 0 ? baseXLeft : baseXRight
    let bushes = new Bushes({
      position: new Vector3(xPos += position.position.x, 0, position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      rotation: new Euler(position.rotation.x, position.rotation.y, position.rotation.z)
    })

    return bushes
  }

  createTrain(position, index, tileNb, nbToGenerate, start) {
    let xPos = index === 0 ? baseXLeft : baseXRight
    let train = new Train({
      position: new Vector3(xPos += position.position.x, 0., position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      rotation: new Vector3(position.rotation.x, position.rotation.y, position.rotation.z)
    })

    return train
  }

  createCoq(position, index, tileNb, nbToGenerate, start) {
    let xPos = index === 0 ? baseXLeft : baseXRight
    let coq = new Coq({
      position: new Vector3(xPos + position.position.x, -.5, position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      rotation: new Vector3(position.rotation.x, position.rotation.y, position.rotation.z)
    })

    return coq
  }

  createPanneau(position, index, tileNb, nbToGenerate, start) {
    let xPos = index === 0 ? baseXLeft : baseXRight
    let panneau = new Panneau({
      position: new Vector3(xPos + position.position.x, 0., position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      rotation: new Vector3(position.rotation.x, position.rotation.y, position.rotation.z)
    })

    return panneau
  }

  createArc(position, index, tileNb, nbToGenerate, start) {
    // console.log('ON PASSE LA')
    let xPos = index === 0 ? baseXLeft : baseXRight
    let arc = new Arc({
      position: new Vector3(xPos + position.position.x, 0., position.position.z + (start ? (tileNb * tilesZpos) : (tileNb + 1) * tilesZpos)),
      scale: new Vector3(position.scale.x, position.scale.y, position.scale.z),
      // rotation: new Vector3(30, position.rotation.y, position.rotation.z)
      // rotation: new Euler( position.rotation.x,0, position.rotation.z * -1 )
      rotation: new Euler(position.rotation.x, position.rotation.y, position.rotation.z)

    })

    return arc
  }

  destroy() {
    this.base.geometry.dispose()
  }


  sceneUpdate(scene) {
    this.tiles.forEach((tile, tilesIndex) => {

      tile.forEach((decor, index) => {
        decor.base.position.z -= gameConfig.decorSpeed * gameConfig.globalSpeed * gameConfig.speed
        if (decor.base.position.z < -13) {
          tile.splice(index, 1);
          decor.destroy()
          scene.removeComponent(decor)
          if (!this.canGenerate) {
            this.generateTiles(1, scene, false, null)
            this.canGenerate = true
          }

          if (tile.length === 0 && tilesIndex === 0) {
            // this.tiles.splice(tilesIndex, 1)
            // console.log('GENERATE NEW TILES')
            // this.generateTiles(1, scene, false)
            this.canGenerate = false
          }

          if (tile.length === 0) {
            this.tiles.splice(tilesIndex, 1)
          }
        }
      })
    })

    // if (this.lastLeft.base.position.z < 50 && this.lastRight.base.position.z & 50 && !this.canGenerate) {
    //   this.generateTiles(1, scene, false)
    //   this.canGenerate = true
    // }
  }


  update(dt) {
    super.update(dt)
  }

}

