<template>
  <div class="checkbox-field">
    <input
      class="checkbox-field__item"
      type="checkbox"
      v-model="value"
      value="true"
      :id="slug"
      style="display:none"
      @change="onChange"
    />
    <label class="u-row u-block u-middle" :for="slug">
      <div class="u-w10of12 t-text--small">
        {{ name }}
      </div>
      <div class="u-w2of12 u-right">
        <div class="checkbox-field__toggle" :class="{'is-checked': value.includes('true')}">
          <div class="checkbox-field__toggle__span" />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxField',

  props: {
    name: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    default: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      value: this.default ? ['true'] : []
    }
  },

  methods: {
    onChange() {
      this.$emit('onChange', this.value.includes('true'))
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/easings'
@import '~@/styles/settings/variables'

.checkbox-field
  &__toggle
    position relative
    display inline-block
    width 30px
    height 10px
    cursor pointer
    border-radius 15px
    // overflow hidden
    background $colors.blue
    transform translate3d(0,0,0)

    &:before
      content ''
      position relative
      top 0
      left 0
      width 100%
      height 100%
      opacity 0
      border-radius 15px
      display block
      background: $colors.red
      transition .3s opacity linear

    &__span
      position absolute
      top 0
      left 0
      width 10px
      height 10px
      border-radius 15px
      overflow hidden
      background-color: $colors.black
      transform translateZ(0)
      transition transform .2s linear

  &__toggle.is-checked
    &:before
      opacity 1

    .checkbox-field__toggle__span
      transform: translateX(20px) translateZ(0)
      box-shadow: 0 3px 8px rgba($colors.blue, .2)

      &:before
        opacity: 1
</style>