<template>
  <div class="loader u-wrapper-panel u-bg-white u-row u-middle u-center u-z-middle">
    <div class="loader__svgs u-w2of12 u-box-1by1">
      <svg class="loader__svg-base u-wrapper-panel" preserveAspectRatio="xMidYMid meet">
        <use xlink:href="#circle-small"/>
      </svg>
      <svg
        class="loader__svg-over u-wrapper-panel"
        preserveAspectRatio="xMidYMid meet"
      >
        <use xlink:href="#circle-small"/>
      </svg>
      <img class="u-wrapper-panel u-fit-contain u-pad-xl" src="/images/bright__logo--shitty-centered.png">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLeaving: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.loader
  opacity 1
  background #933DEB
  transition opacity 1s 1s linear

  &.custom-leave-active
    opacity 0

  &.custom-leave-active:after
    opacity 1
    transform translateY(-50%) translateZ(0)

  &__svg-base
    stroke #0048FF
    opacity .3

  &__svg-over
    stroke #0048FF
    stroke-dasharray 227
    transform rotate(-90deg)
    animation: 3s ease-in-out-quad infinite around

  &__svgs
    opacity 1
    transition opacity .5s linear
  &.custom-leave-active &__svgs
    opacity 0

@keyframes around
  0%
    transform rotate(-90deg)
    stroke-dashoffset 227

  50%
    transform rotate(90deg)
    stroke-dashoffset 0

  100%
    transform rotate(270deg)
    stroke-dashoffset -227
</style>