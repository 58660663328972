import { sRGBEncoding, RGBFormat, MeshStandardMaterial, DoubleSide, MeshBasicMaterial, Vector2 } from "three"

import getPMREMfromEquirectangular from "../utils/ibl/getPMREMFromEquirectangular"
import Files from "../../managers/assetsManager/Files"
import createTexture from "../utils/createTexture"
import CharacterMaterial from '../materials/CharacterMaterial/CharacterMaterial'

const textures = {}
const materials = {}
const meshs = {}
let renderer = null
function init(opts) {
	renderer = opts.renderer
}

function appLoaded() {
	// textures.envmap = getPMREMfromEquirectangular({
	// 	img: Files.get('game').soft,
	// 	flipY: true,
	// 	format: RGBFormat,
	// 	encoding: sRGBEncoding
	// }, renderer)

	// textures.char1_albedo = createTexture({
	// 	img: Files.get('global').char1_albedo,
	// 	encoding: sRGBEncoding
	// })

	// textures.matcap = createTexture({
	// 	img: Files.get('game').matcap,
	// 	encoding: sRGBEncoding
	// })

	// textures.char1_normal = createTexture({
	// 	img: Files.get('global').char1_normal
	// })

	textures.sprite = createTexture({
		img: Files.get('game').sprite,
		encoding: sRGBEncoding
	})


	materials.character1 = new CharacterMaterial({
		map: null,
		roughness: 1,
		metalness: 1,
		envMapIntensity: 1,
		color: "white",
		// bumpScale: 12,

		// bumpMap: textures.char_height,
		// bumpMap: textures.char_height,
		normalScale: new Vector2(10, 10),
		normalMap: textures.char1_normal
	})

}

export default {
	init,
	appLoaded,
	textures,
	materials
}
