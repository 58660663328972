import gsap from "gsap"

import GameStore from "../../store/GameStore"
import gui from "../../canvas/utils/gui"
import { gameConfig } from "../../canvas/config/gameConfig"
import { emitter } from '@/utils/emitter'
import { events } from "../../canvas/config/events"
import AppStore from "../../store/AppStore"
import DebugStore from "../../store/DebugStore"

function init() {
	bind()
}

function bind() {
	emitter.on(events.INIT, setInit)
	emitter.on(events.READY, ready)
	emitter.on(events.START, start)
	emitter.on(events.STOP, stop)
	emitter.on(events.RESET, reset)
}


function setInit() {
	AppStore.gameInit.set(true)
	if (AppStore.gameInit.get() && AppStore.appReady.get()) AppStore.currentView.set('game')

}

function ready() {
	if (GameStore.ready.current) return
	GameStore.ready.set(true)
}

function start() {
	if (GameStore.started.current) return
	GameStore.started.set(true)
}

function pause() {
	if (GameStore.paused.current) return
	GameStore.paused.set(true)
}

function resume() {
	if (!GameStore.paused.current) return
	GameStore.paused.set(false)
}


function stop() {
	reset()
	AppStore.currentView.set('default')
}


function reset() {
		GameStore.started.set(false)
		GameStore.ready.set(false)
		GameStore.character.set(1)
		gameConfig.speedSpawner = 1.5
		gameConfig.coinSpeed = .65

		GameStore.reset.dispatch()
		
}

function getRunningTime() {
	return Math.floor(Math.random() * (gameConfig.maxRunModeTiming - gameConfig.minRunModeTiming + 1) + gameConfig.minRunModeTiming);
}

function setQuizzMode() {
	GameStore.runMode.set(false)
	GameStore.quizzMode.set(true)
}

function setRunMode() {
	GameStore.runMode.set(true)
	GameStore.quizzMode.set(false)
}

function setPlayerCanAnswer() {
	if (!GameStore.quizzMode.current) return
	GameStore.canAnswer.set(true)
}

function changeCharacter() {
	if (GameStore.character.current < 2) {
		GameStore.character.set(GameStore.character.current++)
	}
}

function endGame() {

}

function restart() {
	reset()
	resume()
	start()
}


/// #if DEVELOPMENT
function toGui() {
	const o = {
		gameStart: start,
		gamePause: pause,
		gameResume: resume,
		gameRestart: restart,
		quizzMode: setQuizzMode,
		playerCanAnswer: setPlayerCanAnswer,
	}
	const folder = gui.folder('Game State')

	folder.add(o, 'gameStart').name('Start')
	folder.add(o, 'gamePause').name('Pause')
	folder.add(o, 'gameResume').name('Resume')
	folder.add(o, 'gameRestart').name('Restart')
	folder.add(o, 'quizzMode').name('Quizz mode')
	folder.add(o, 'playerCanAnswer').name('Can Answer')
}
if (DebugStore.useGui.current) {
	toGui()
}
/// #endif

export default {
	init,
	start,
	pause,
	resume,
	restart,
	ready,
	stop,
	getRunningTime,
	setQuizzMode,
	setRunMode,
	setPlayerCanAnswer,
	changeCharacter
}
