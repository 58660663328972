import { ShaderLib, UniformsUtils, MeshStandardMaterial } from 'three'
import Assets from '../../controllers/Assets'

import vert from './floor-material.vert'
import frag from './floor-material.frag'

export default class FloorMaterial extends MeshStandardMaterial {

  constructor(props) {
    super({
      vertexShader: vert,
      fragmentShader: frag,
      uniforms: {
        matcap: Assets.textures.matcap2,
        ...UniformsUtils.clone(ShaderLib.physical.uniforms),
      },
    })

    console.log('AYO', vert)
  }

}
