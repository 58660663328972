const events = {
  SHOW_QUESTION: "GAME:SHOW_QUESTION",
  HIDE_QUESTION: "GAME:HIDE_QUESTION",
  MALUS: "GAME:MALUS",
  BONUS: "GAME:BONUS",
  ANSWER_START: "GAME:ANSWER_START",
  ANSWER_STOP: "GAME:ANSWER_STOP",
  CANDIDATE_CHANGE: "GAME:CANDIDATE_CHANGE",
  INIT: "GAME:INIT",
  READY: "GAME:READY",
  CANDIDATES: "GAME:CANDIDATES",
  START: "GAME:START",
  RESET: "GAME:RESET",
  PAUSE: "GAME:PAUSE",
  RESUME: "GAME:RESUME",
  END: "GAME:END",
  STOP: "GAME:STOP"
}

export {
  events
}