import { isMobile } from '../utils'
import w from '../utils/store/writeable'
// import Signal from '../utils/store/Signal'

const CanvasStore = {
	bgColor: w("#FFFFFF"),
	enableShadows: w(true),
	canvasPixelRatio: w(1),
	canvasSize: w([window.innerWidth, window.innerHeight]),
	drawingBufferSize: w([window.innerWidth, window.innerHeight])
}

/// #if DEVELOPMENT
window.canvasStore = CanvasStore
/// #endif

export default CanvasStore
