import AppStore from "../../store/AppStore"

const { visibilityEventName, visibilityHiddenProp } = getVisibilityProps()
let orientationTimer
function init() {
	document.addEventListener( visibilityEventName, onVisibilityChange, true )

	window.addEventListener( 'orientationchange', onOrientationChange, false )
	window.addEventListener( 'resize', onResize, true )
}

function onVisibilityChange( e ) {
	const hidden = document[ visibilityHiddenProp ]
	AppStore.siteVisible.set( ! hidden )
}

function onResize() {
	update()
}

function getVisibilityProps() {
	const props = { visbilityEventName: null, visibilityHiddenProp: null }
	if ( typeof document.hidden !== 'undefined' ) {
		props.visibilityEventName = 'visibilitychange'
		props.visibilityHiddenProp = 'hidden'
	} else if ( typeof document.msHidden !== 'undefined' ) {
		props.visibilityEventName = 'msvisibilitychange'
		props.visibilityHiddenProp = 'msHidden'
	} else if ( typeof document.webkitHidden !== 'undefined' ) {
		props.visibilityEventName = 'webkitvisibilitychange'
		props.visibilityHiddenProp = 'webkitHidden'
	}
	return props
}

function onOrientationChange() {
	update()

	window.clearTimeout( orientationTimer )
	orientationTimer = window.setTimeout( () => {
		update()
	} )
}

function update() {
	const width = window.innerWidth
	const height = window.innerHeight

	const storeViewport = AppStore.viewportSize.get()

	if ( height === storeViewport[ 1 ] && width === storeViewport[ 0 ] ) return
	storeViewport[ 0 ] = width
	storeViewport[ 1 ] = height
	AppStore.viewportRatio.set( width / height, true )

	AppStore.viewportSize.set( storeViewport, true )
}

export default {
	init, update
}
