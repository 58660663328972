
<template>
  <button
    class="t-button-quizz u-cursor-pointer u-center t-text u-relative"
    :class="{'is-true': isTrue, 'is-selected': isSelected, 'is-clicking': isSelecting}"
    ref="btn"
  >
    <div class="t-button-quizz__content u-relative t-h3 t-black u-uppercase">
      <slot/>
      <svg class="t-button-quizz__icon u-fill-black" :class="{'is-left': isLeft}" preserveAspectRatio="xMidYMid meet">
        <use :xlink:href="isLeft ? '#arrow-left' : '#arrow-right'"/>
      </svg>
    </div>
    <div class="t-button-quizz__over u-wrapper-panel u-white t-h3 t-black u-uppercase">
      <slot/>
      <svg class="t-button-quizz__icon u-fill-black" :class="{'is-left': isLeft}" preserveAspectRatio="xMidYMid meet">
        <use :xlink:href="isLeft ? '#arrow-left' : '#arrow-right'"/>
      </svg>
    </div>
  </button>
</template>

<script>
import Button from './Button'

const duration = 2

export default {
  mixins: [Button],

  data () {
    return {
      durationString: duration + 's',
      isSelected: false,
      isSelecting: false
    }
  },

  props: {
    isTrue: {
      type: Boolean,
      default: false
    },
    isLeft: {
      type: Boolean,
      default: false
    },
    isFakeSelected: {
      type: Boolean,
      default: false
    },
  },

  mounted () {
    this.$refs.btn.addEventListener(this.$device.pointerdown, this.select)
  },

  beforeUnmount () {
    this.$refs.btn.addEventListener(this.$device.pointerdown, this.select)
    clearTimeout(this.timeout)
  },

  methods: {
    onPointerdown () {
      this.onSelecting()
    },

    onPointerup () {
      this.onSelectingOff()
    },

    onSelecting () {
      this.isSelecting = true
      this.timeout = setTimeout(this.select, duration * 1000 + 200)
    },

    select () {
      if (!this.isSelected) {
        this.isSelected = true
        this.$emit('selected')
      }
    },

    onSelectingOff () {
      this.isSelecting = false
      clearTimeout(this.timeout)
    }
  },

  watch: {
    isFakeSelected () {
      if (this.isFakeSelected) {
        this.onSelecting()
      }
      else {
       this.onSelectingOff() 
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

padding = clamp(10px, .4em, 20px) clamp(20px, 1.4em, 40px)
radius = 10px
duration = v-bind(durationString)

.t-button-quizz
  position relative
  color: $colors.black
  background-color transparent
  z-index 0
  border-radius radius
  background-color: $colors.white
  padding 0
  
  &__icon
    position absolute
    height .6em
    width .6em
    top calc(50% - .3em)
    right 15px
    
    &.is-left
      right auto
      left 15px
    
  
  &:before, &:after
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius radius
    background-color: $colors.blue
      
  &:before
    opacity 0
    transform scale(.2, .2) translateZ(0)
    transition: transform duration ease-out-quad, opacity duration linear

  &:after
    transition opacity .2s linear
    background-color: #DC2020
    opacity 0
  
  &.is-true:after
    background-color: #20DC43

  &__content
    padding: padding
    opacity 1
    z-index 1
    transition opacity duration linear

  &__over
    padding: padding
    opacity 0
    z-index 2
    transition opacity duration linear
  
  &.is-clicking:before
    opacity 1
    transform scale(1, 1) translateZ(0)

  &.is-clicking &
    &__content
      opacity 0
    &__over
      opacity 1
  
  &.is-selected:after
    opacity 1

  &.is-selected &
    &__content
      opacity 0
    &__over
      opacity 1

</style>