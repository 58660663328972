import {
	Color,
	Quaternion,
	Vector2,
	Vector3,
	Vector4,
	Matrix4,
	Box3,
	Box2,
	PlaneBufferGeometry,
	Euler
} from 'three'
import { raf, fpsLimiter } from '@internet/raf'
// import { TweenMax, Power4 } from 'gsap/all'

import DebugStore from '../store/DebugStore'

// import Stars from 'canvas/components/stars/Stars'
import gui from './utils/gui'

import Pipeline from './pipeline/Pipeline'

import PrerenderScene from './scenes/PrerenderScene'

import Scenes from './controllers/Scenes'
import Renderer from './controllers/Renderer'

import Pool from './utils/Pool'
import Assets from './controllers/Assets'
import Transitions from './controllers/Transitions'
import addChunks from './shaders/chunks/addChunks'
// import addChunks from 'canvas/shaders/chunks/addChunks'


function init({ parent }) {
	Pool.assign(Quaternion, (quaternion) => quaternion.set(0, 0, 0, 0))
	Pool.assign(Color, (color) => color.setRGB(0, 0, 0))
	Pool.assign(Vector2, (vector) => vector.setScalar(0))
	Pool.assign(Vector3, (vector) => vector.setScalar(0))
	Pool.assign(Vector4, (vector) => vector.setScalar(0))
	Pool.assign(Euler, (euler) => euler.set(0, 0, 0, 'XYZ'))
	Pool.assign(Matrix4)
	Pool.assign(Box3)
	Pool.assign(Box2)

	// Add simple reusable geometry
	if (!PlaneBufferGeometry.simple)
		PlaneBufferGeometry.simple = new PlaneBufferGeometry()

	// Add custom shader chunks
	addChunks()

	// Quality.init()
	Renderer.init({ parent })
	Assets.init({ renderer: Renderer.getRenderer() })
	Scenes.init()
	Transitions.init()

	raf.add(fpsLimiter(60, Renderer.tick))

	// // QUICK > 60Hz TEST
	// raf.add( ( dt ) => Renderer.tick( dt * 0.5 ) );
	// raf.add( ( dt ) => Renderer.tick( dt * 0.5 ) );
}

function prerenderCycle(scene, resolve) {
	if (scene.prerendered) return resolve()
	Pipeline.prerender(scene, true)
	window.setTimeout(() => prerenderCycle(scene, resolve), 10)
}

// Prerender some materials / assets to pre-bind geo, shaders, textures to the gpu
function prerender() {
	return new Promise(resolve => {
		const prerenderScene = new PrerenderScene()
		Promise.resolve()
			.then(() => new Promise(resolve => prerenderCycle(prerenderScene, resolve)))
			.then(() => new Promise(resolve => { setTimeout(resolve, 10) }))
			.then(() => prerenderScene.destroy())
			.then(() => new Promise(resolve => { setTimeout(resolve, 20) }))
			.then(resolve)
	})
}

function appLoaded() {
	Assets.appLoaded()
	Renderer.appLoaded()
}

// Called when the app and the packed assets are ready to be used
function appReady() {
	const o = {
		debugCamera: () => DebugStore.debugCamera.update(v => !v),
		debugPhysics: () => DebugStore.debugPhysics.update(v => !v)
	}
	gui.add(o, 'debugCamera').name('Orbit Controls')
	gui.add(o, 'debugPhysics').name('Debug Physics')

	Scenes.appReady()
	Pipeline.appReady()
}

export default {
	init,
	prerender,
	appLoaded,
	appReady
}
