<template>
  <div>
    <transition name="custom" duration="1200">
      <app-popin
        v-if="introIndex === 0 && visible"
        title="Pour répondre à une question place ton personnage sur le chemin de la réponse que tu veux choisir"
        image="/images/tuto--3.png"
        svg="interrogation"
        pictoColor="blue"
        cta="D'accord"
        @onClick="onNext"
      />
    </transition>
    <transition name="custom" duration="1200">
      <app-popin
        v-if="introIndex === 1 && visible"
        title="Attention la question est chronométrée, plus tu réponds vite plus tu gagnes des votes"
        image="/images/tuto--4.png"
        svg="interrogation"
        pictoColor="blue"
        cta="Reprendre"
        @click="onNext"
      />
    </transition>
  </div>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'

export default {
  components: {
    AppPopin
  },

  data () {
    return {
      introIndex: -1,
      visible: false
    }
  },

  mounted () {
    requestAnimationFrame(() => {
      this.visible = true
      this.introIndex = 0
    })
  },

  methods: {
    onNext () {
      this.introIndex++
      this.visible = false

      setTimeout(() => {
        if (this.introIndex > 1) {
          this.$emit('end')
        }
        else {
          this.visible = true
        }
      }, 1300)
    }
  }
}

</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.intro
  &:before
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius 30px
    background-color: $colors.white
</style>