<template>
  <div class="u-wrapper-panel u-pad-x-1of12 u-pad-y-xl u-pad-md--sm u-bg-white u-overflow-y-scroll u-invisible-scroll">
    <div class="u-row u-top u-between">
      <div class="u-w6of12 u-w8of12--sm">
        <img src="/images/logo--colored.png" class="u-nlock u-full-width">
      </div>
      <div class="u-flex u-right u-w4of12">
        <app-button-round @click="$emit('close')">
          <svg class="u-wrapper-panel u-stroke-red" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#close"/>
          </svg>
        </app-button-round>
      </div>
    </div>
    <div class="u-row u-top u-pad-y-lg">
      <slot />
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound
  }
}
</script>