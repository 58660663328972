import { RafTimer } from '@internet/raf'

const timers = []

export function updateTimers( dt ) {
	let n = timers.length

	while ( n-- ) {
		timers[ n ].update( dt )
		if ( ! timers[ n ] || timers[ n ]._stopped ) {
			timers[ n ] && timers[ n ].dispose()
			timers.splice( n, 1 )
		}
	}
}

export default function timer( delay = 1000, cb ) {
	const timer = new RafTimer( delay, cb )
	timers.push( timer )

	function destroy() {
		timer.dispose()
		const index = timers.indexOf( timer )
		if ( ~ index ) timers.splice( index, 1 )
	}

	return {
		destroy
	}
}
