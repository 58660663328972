import {
  Vector3,
  InstancedMesh,
  DynamicDrawUsage,
  Matrix4,
  Euler,
  Quaternion,
} from 'three'


import GameObject from '../../abstractions/GameObject'

import Files from '../../../managers/assetsManager/Files'
import { Colors } from '../../../utils/styleguide'
import { gameConfig } from '../../config/gameConfig'
import gsap from 'gsap'

const COUNT = 6
let material
const geoPool = []

export default class HitParticles extends GameObject {

  init({ character } = props) {
    const mesh = Files.get('game').emoji_dizzy.scene.children[0]
    this.geo = mesh.geometry
    this.time = 0
    this.character = character
    const material = mesh.material

    const colorParsChunk = [
      'vec3 displace(vec3 v) {',
      'float dist = distance(v.z, cameraPosition.z);',
      'if( dist < 0.0) { dist = 0.0; }',
      'float addY = dist * dist;',
      `return vec3(v.x, v.y - addY * (1. * ${gameConfig.bendY.toString()}), v.z);`,
      '}',
      '#include <common>'
    ].join('\n');

    const instanceColorChunk = [
      '#include <worldpos_vertex>',
      // 'transformedNormal = normalMatrix * transformedNormal;',
      'transformed = worldPosition.xyz;',
      'transformed = displace(transformed);',
      '	float e = -.01;',
      'vec3 dA = e * normalize(cross(normal.yzx, normal));',
      'vec3 dB = e * normalize(cross(dA, normal));',
      'vec3 pA = worldPosition.xyz + dA ;',
      'vec3 pB = worldPosition.xyz + dB ;',
      'vec3 hA = displace(pA);',
      'vec3 hB =  displace(pB);',
      'vec4 testNormal = vec4(cross(hA-transformed, hB-transformed), 1.);',
      'vNormal = normalize(normalMatrix * testNormal.xyz);',
      'vNormal = normalize(transformedNormal);',
      'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
    ].join('\n');

    const fragmentParsChunk = [
      'varying vec3 vInstanceColor;',
      '#include <common>'
    ].join('\n');

    material.onBeforeCompile = function (shader) {

      shader.vertexShader = shader.vertexShader
        .replace('#include <common>', colorParsChunk)
        .replace('#include <worldpos_vertex>', instanceColorChunk);

      shader.fragmentShader = shader.fragmentShader
        .replace('#include <common>', fragmentParsChunk)

      //console.log( shader.uniforms );
      //console.log( shader.vertexShader );
      //console.log( shader.fragmentShader );

    };

    this.geo.computeVertexNormals();

    this.base = new InstancedMesh(this.geo, material, COUNT)
    this.base.castShadow = true
    // this.base.receiveShadow = true
    this.base.instanceMatrix.setUsage(DynamicDrawUsage);
    this.base.frustumCulled = true

    const angleStep = (Math.PI * 2) / COUNT
    for (let i = 0; i < COUNT; i++) {
      let matrix = new Matrix4();

      let position = new Vector3();
      this.rotation = new Euler();
      this.quaternion = new Quaternion();
      this.scale = new Vector3();

      position.y = 2
      position.x = Math.sin(i + angleStep) * 2
      position.z = -10 + Math.cos(i + angleStep) * 2

      this.rotation.x = .8 - ((Math.floor(Math.random() * 10) + 1) / 10) / 2
      this.rotation.y = 3.5 - ((Math.floor(Math.random() * 10) + 1) / 10) / 2
      this.rotation.z = (Math.floor(Math.random() * 10) + 1) / 10;

      this.quaternion.setFromEuler(this.rotation);

      this.scaleValue = 1.3

      this.scale.x = this.scale.y = this.scale.z = 0;

      matrix.compose(position, this.quaternion, this.scale);

      this.base.setMatrixAt(i, matrix);
    }
    this.bind()
   
    this.tlDizzy = gsap.timeline({ delay: .025, paused: true,onComplete: (() => this.playinDizzy = false) })
    .to(this.scale, { x: this.scaleValue, y: this.scaleValue, z: this.scaleValue, duration: .1 })
    .to(this.scale, { x: 0, y: 0, z: 0, duration: .1, delay: 1.35 })
  }

  bind() {
    
  }

  unbind() {
  }

  playDizzy() {
    this.tlDizzy.play(0)
    this.playinDizzy = true
  }
  

  animationDizzy() {
    const angleStep = (Math.PI * 2) / COUNT
    for (let i = 0; i < COUNT; i++) {
      let matrix = new Matrix4();

      let position = new Vector3();
      position.y = 7.5 + Math.sin(i + angleStep + (this.time / 230)) * .1
      position.x = this.character.base.position.x + Math.sin(i + angleStep + (this.time / 230)) * 1.8
      position.z = this.character.base.position.z + Math.cos(i + angleStep + (this.time / 230)) * 1.8

      matrix.compose(position, this.quaternion, this.scale);

      this.base.setMatrixAt(i, matrix);
    }
  }


  update(dt) {
    super.update(dt)
    this.time += dt

    // if (this.playinDizzy)
    
    this.animationDizzy()

    this.base.instanceMatrix.needsUpdate = true;
  }

  beforeDestroy() {
    geoPool.push(this.geo)
    this.data = this.geo = null
    this.time = 0
    this.unbind()
  }


  destroy() {
    if(this.geo) this.geo.dispose()
    super.destroy()
  }

}


