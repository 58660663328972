/* eslint-disable */
import Camera from '../../abstractions/Camera';

/// #if DEVELOPMENT
import { Vector3 } from 'three';
import DebugStore from '../../../store/DebugStore';
import OrbitControls from '../../utils/OrbitControls';
/// #endif
/* eslint-enable */

export default class DebugCamera extends Camera {

	/// #if DEVELOPMENT
	constructor() {
		super()
		this.base.position
			.set(0, 0.2, 1)
			.multiplyScalar(5)
		this.base.lookAt(new Vector3(0, 0, 0))

		this.controls = new OrbitControls(this.base, document.body)
		this.controls.enabled = true
		this.controls.maxDistance = 1500
		this.controls.minDistance = 0

		DebugStore.debugCamera.subscribe(this.toggleCamera, this)
		DebugStore.orbitCameraStats.current.position = this.base.position
		DebugStore.orbitCameraStats.current.rotation = this.base.rotation

		this.toggleCamera(DebugStore.debugCamera.current)
	}

	toggleCamera(v) {
		this.controls.enabled = !!v
	}

	beforeDestroy() {
		DebugStore.debugCamera.unsubscribe(this.toggleCamera, this)
		this.controls.dispose()
		super.beforeDestroy()
	}
	/// #endif

}

let camera = null
DebugCamera.get = function get() {
	if (!camera) {
		camera = new DebugCamera()
	}

	return camera
}
