import GameObject from '../../abstractions/GameObject'
import Files from '../../../managers/assetsManager/Files'
import { Vector3 } from 'three';
import { gameConfig } from '../../config/gameConfig';

export default class StreetLamp extends GameObject {

  init({ position, rotation, scale } = props) {
    const gameAssets = Files.get('game')
    this.name = "streetlamp"
    this.mesh = gameAssets['lampadaire'].scene.children[0].clone()

    this.position = position || new Vector3();
    this.scale = scale || new Vector3();
    this.rotation = rotation || new Vector3();

    this.base = this.mesh
    this.base.scale.set(this.scale.x, this.scale.y, this.scale.z)
    this.base.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
    this.base.position.set(this.position.x, this.position.y, this.position.z)

    const material = this.mesh.material
    const colorParsChunk = [
      'vec3 displace(vec3 v) {',
      'float dist = distance(v.z, cameraPosition.z);',
      'float distX = distance(v.x, cameraPosition.x);',
      'if( dist < 0.0) { dist = 0.0; }',
      'if( distX < 0.0) { distX = 0.0; }',
      'float addY = dist * dist;',
      'float addX = distX * distX;',
      `return vec3(v.x, v.y - (addY * (1. *${gameConfig.bendY.toString()})) -  (addX * (1. * 0.002)), v.z);`,
      '}',
      '#include <common>'
    ].join('\n');

    const instanceColorChunk = [
      '#include <worldpos_vertex>',
      'transformed = worldPosition.xyz;',
      'transformed = displace(transformed);',
      '	float e = -.01;',
      'vec3 dA = e * normalize(cross(normal.yzx, normal));',
      'vec3 dB = e * normalize(cross(dA, normal));',
      'vec3 pA = worldPosition.xyz + dA ;',
      'vec3 pB = worldPosition.xyz + dB ;',
      'vec3 hA = displace(pA);',
      'vec3 hB =  displace(pB);',
      'vec4 testNormal = vec4(cross(hA-transformed, hB-transformed), 1.);',
      'vNormal = normalize(normalMatrix * testNormal.xyz);',
      'vNormal = normalize(transformedNormal);',
      'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
    ].join('\n');


    material.onBeforeCompile = function (shader) {

      shader.vertexShader = shader.vertexShader
        .replace('#include <common>', colorParsChunk)
        .replace('#include <worldpos_vertex>', instanceColorChunk);
    };
    this.base.castShadow = true
    this.base.receiveShadow = true
  }

  destroy() {
    this.mesh.geometry.dispose()
  }


  update(dt) {
    super.update(dt)
  }

}

