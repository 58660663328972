import { RafTimer } from '@internet/raf'

export default class BaseComponent {

	constructor( props ) {
		this.isComponent = true
		this.components = []
		this.dynamicComponents = []
		this.refs = {}
		this.tweens = {}
		this.timers = []
		this.props = props
	}

	init() {}
	beforeDestroy() {}

	add( object ) {
		this.base.add( object )
		return object
	}

	remove( object ) {
		this.base.remove( object )
		return null
	}

	addComponent( component, props ) {
		if ( ! props ) props = {}
		const mountTo = props.mountTo || this.base

		// eslint-disable-next-line new-cap
		if ( ! component.isComponent ) component = new component( props )

		if ( ~ this.components.indexOf( component ) ) return component
		if ( ~ this.dynamicComponents.indexOf( component ) ) return component
		// console.log( 'ADD', component, component.parentComponent );
		if ( component.parentComponent ) component.parentComponent.removeComponent( component )
		component.parentComponent = this
		if ( ! component.static ) this.dynamicComponents.push( component )

		this.components.push( component )
		if ( mountTo && component.base ) mountTo.add( component.base )
		if ( component.added ) component.added( this )
		return component
	}

	removeComponent( component ) {
		// console.log( 'REMOVE', component );
		const index = this.components.indexOf( component )
		const dynIndex = this.dynamicComponents.indexOf( component )
		if ( ! ~ index ) return
		component.parentComponent = null
		this.components.splice( index, 1 )
		if ( ~ dynIndex ) this.dynamicComponents.splice( dynIndex, 1 )
		if ( this.base && component.base ) this.base.remove( component.base )
		if ( component.removed ) component.removed( this )
		return null
	}

	update( dt ) {
		this.updateTimers( dt )
		if ( this.destroyed ) return // Needed in case a timer destroy the component

		for ( let i = 0, l = this.dynamicComponents.length; i < l; i++ ) {
			this.dynamicComponents[ i ] && this.dynamicComponents[ i ].update( dt )
		}
	}

	updateTimers( dt ) {
		let n = this.timers.length
		const timers = this.timers
		while ( n-- ) {
			timers[ n ].update( dt )
			if ( timers._stopped ) {
				timers[ n ].dispose()
				timers.splice( n, 1 )
			}
		}
	}


	timer( delay, cb ) {
		if ( this.destroyed ) return
		if ( ! cb ) {
			return new Promise( resolve => {
				const timer = new RafTimer( delay, resolve )
				this.timers.push( timer )
			} )
		} else {
			const timer = new RafTimer( delay, cb )
			this.timers.push( timer )
			return timer
		}
	}

	destroy() {
		if ( this.destroyed ) return
		this.beforeDestroy()

		// Remove timers
		for ( let i = 0, l = this.timers.length; i < l; i++ ) {
			this.timers[ i ].dispose()
		}

		// Remove from parent
		if ( this.parentComponent ) this.parentComponent.removeComponent( this )

		// Destroy each children components
		for ( let i = this.components.length - 1; i >= 0; i-- ) {
			this.components[ i ].destroy()
		}

		// Remove left children
		if ( this.base ) {
			for ( let i = this.base.children.length - 1; i >= 0; i-- ) {
				this.base.remove( this.base.children[ i ] )
			}
		}


		for ( const k in this.tweens ) this.tweens[ k ] && this.tweens[ k ].kill()
		this.tweens = null

		if ( this.base && this.base.parent ) this.base.parent.remove( this.base )

		// De-reference variables
		this.refs = null
		this.props = null
		this.base = null
		this.destroyed = true
		this.components = null
		this.dynamicComponents = null
		this.anims = null
		this.timers = null
	}

}
