import { Color } from 'three';
import { Object3D, Vector3, Mesh, MeshBasicMaterial, SphereBufferGeometry, PlaneBufferGeometry, MeshPhongMaterial, BoxBufferGeometry } from 'three'

import GameObject from '../../abstractions/GameObject'
import SkyMaterial from '../../materials/SkyMaterial/SkyMaterial';


export default class Sky extends GameObject {

  init({ position, rotation, scale } = props) {
    this.name = "sky"
    this.position = position || new Vector3();
    this.scale = scale || new Vector3(.1, .1, .1);
    this.rotation = rotation || new Vector3();

    const material = new SkyMaterial()
    const skyGeo = new SphereBufferGeometry(650, 32, 15);
    this.mesh = new Mesh(skyGeo, material);
    this.base = this.mesh
    this.base.scale.set(this.scale.x, this.scale.y, this.scale.z)
    this.base.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
    this.base.position.set(this.position.x, this.position.y, this.position.z)

  }

  destroy() {
    this.base.geometry.dispose()
    super.destroy()
  }

  update(dt) {
    super.update(dt)
  }

}

