import { Scene as ThreeScene, Color } from 'three'

import Renderer from '../controllers/Renderer'

import Camera from './Camera'
import BaseComponent from './BaseComponent'

class Scene extends BaseComponent {

	constructor( props = {} ) {
		super()
		this.isScene = true
		this.scene = this.base = new ThreeScene()
		this.background = new Color( 0, 0, 0 )

		if ( ! this.currentCamera ) this.fallbackCamera()
		if ( ! props.noInit ) this.init( props )
		if ( ! this.props ) this.props = props
	}

	fallbackCamera() {
		if ( ! this.defaultCamera )
			this.defaultCamera = this.addComponent( new Camera() )

		this.useCamera( this.defaultCamera )
	}

	useCamera( cam ) {
		if ( this.currentCamera ) this.previousCamera = this.currentCamera
		this.currentCamera = cam

		if ( ! this.currentCamera ) this.fallbackCamera()
	}

	usePreviousCamera() {
		const currentCam = this.currentCamera
		this.currentCamera = this.previousCamera
		this.previousCamera = currentCam
		if ( ! this.currentCamera ) this.fallbackCamera()
	}

	activated() {}
	deactivated() {}

	enter() {}
	exit() {}

	destroy() {
		Renderer.getRenderer().renderLists.dispose()

		// console.log( Renderer.getRenderer().renderLists )
		// delete Renderer.getRenderer().renderLists[ this.scene.id ]
		// Use beforeDestroy to dispose listeners, geometries, material...
		this.previousCamera = this.currentCamera = this.defaultCamera = null
		super.destroy()
	}

}

export default Scene
