import { createWebHistory } from 'vue-router'

import Styleguide from './views/Styleguide'
import Homepage from './views/Homepage'
import Candidat from './views/Candidat'
import Game from './views/Game'

import messages from './locales'

const routes = [
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: Styleguide,
    meta: {
      isFull: true
    }
  },
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
    meta: {
      isFull: true
    }
  },
  {
    path: '/candidats/:candidat',
    name: 'Candidat',
    component: Candidat,
    meta: {
      isFull: true
    }
  },
  {
    path: '/first-round',
    name: 'Game',
    component: Game,
    meta: {
      isFull: true,
      isSecond: false
    }
  },
  {
    path: '/second-round',
    name: 'GameSecond',
    component: Game,
    meta: {
      isFull: true,
      isSecond: true
    }
  }
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: routes
}
