<template>
  <div class="u-cursor-grab" :class="{'is-grabbing': isPointerdown}">
    <slot></slot>
  </div>
</template>

<script>
import gsap from 'gsap'
import { clamp } from '@/utils/Maths'
import { Manager, Pinch } from 'hammerjs'

export default {
  props: {
    margin: {
      type: Number,
      default: .4
    },
    zoom: {
      type: Number,
      default: .02
    }
  },

  data () {
    return {
      isPointerdown: false
    }
  },

  created () {
    this.pointer = {
      position: {
        x: 0, y: 0
      },
      isDown: false
    }

    this.content = {
      width: 0,
      height: 0,
      el: null
    }

    this.width = 0
    this.height = 0

    this.zoomMarge = {
      x: 0,
      y: 0
    }

    this.transform = {
      scale: 1,
      x: 0,
      y: 0
    }

    this.currentTransform = {
      x: 0,
      y: 0
    }
  },

  mounted () {
    this.baseScale = 1
    this.content.el = this.$el.querySelector('.js-slidable-content')

    this.content.el.addEventListener('loadedmetadata', this.onLoadedMetaData)
    this.$el.addEventListener(this.$device.pointerdown, this.onPointerDown)
    this.$el.addEventListener(this.$device.pointerup, this.onPointerUp)
    this.$el.addEventListener(this.$device.pointermove, this.onPointerMove)

    this.baseScale = 1

    this.mc = new Manager(this.$el)
    this.pinch = new Pinch()
    this.mc.add([this.pinch])

    this.mc.on('pinch', this.onPinch)
  },

  beforeDestroy () {
    this.content.el.removeEventListener('loadedmetadata', this.onLoadedMetaData)
    gsap.killTweensOf(this.transform, 'scale')
  },

  methods: {
    onPinch (event) {
      this.baseScale += (event.scale - 1) * .01

      this.baseScale = clamp(this.baseScale, .8, 2)

      this.onResize()

      this.transform.x = clamp(this.transform.x, this.minMaxTranslate.x.max, this.minMaxTranslate.x.min)
      this.transform.y = clamp(this.transform.y, this.minMaxTranslate.y.max, this.minMaxTranslate.y.min)
    },

    onPointerMove (event) {
      if (this.isPointerdown) {
        const pointerPos = this.$device.getPointerPosition(event)

        let delta = {
          x: this.pointer.position.x - pointerPos.x,
          y: this.pointer.position.y - pointerPos.y
        }

        this.transform.x -= delta.x
        this.transform.y -= delta.y

        // we inverte min and max in clamp because we deal with negatives values
        // yet minMaxTranslate.x.max and minMaxTranslate.x.min are positives
        this.transform.x = clamp(this.transform.x, this.minMaxTranslate.x.max, this.minMaxTranslate.x.min)
        this.transform.y = clamp(this.transform.y, this.minMaxTranslate.y.max, this.minMaxTranslate.y.min)

        this.pointer.position = pointerPos
      }
    },

    onPointerUp () {
      this.isPointerdown = false

      gsap.to(this.transform, {
        scale: 1,
        duration: .6,
        ease: 'power3.inOut'
      })
    },

    onPointerDown (event) {
      this.pointer.position = this.$device.getPointerPosition(event)
      this.isPointerdown = true

      gsap.to(this.transform, {
        scale: 1 - this.zoom,
        duration: .4,
        ease: 'power3.inOut'
      })
    },

    onUpdate () {
      this.currentTransform.x += (this.transform.x - this.currentTransform.x) * .1
      this.currentTransform.y += (this.transform.y - this.currentTransform.y) * .1

      this.$el.style.transform = `translate3d(${this.currentTransform.x}px, ${this.currentTransform.y}px, 0) scale(${this.transform.scale})`
    },

    onResize () {
      const deviceRatio = this.$device.width / this.$device.height
      const contentRatio = this.content.width / this.content.height

      if( contentRatio >= deviceRatio ) {
        this.width = this.$device.height * contentRatio
        this.height = this.$device.height
      }
      else if( contentRatio < deviceRatio ) {
        this.width = this.$device.width
        this.height = this.$device.width * (1/contentRatio)
      }

      this.playMarge = {
        x: this.width * this.margin * this.baseScale,
        y: this.height * this.margin * this.baseScale
      }

      this.width += this.playMarge.x
      this.height += this.playMarge.y

      this.zoomMarge = {
        x: this.width * this.zoom,
        y: this.height * this.zoom,
      }

      this.width += this.zoomMarge.x
      this.height += this.zoomMarge.y

      this.$el.style.width = this.width + 'px'
      this.$el.style.height = this.height + 'px'

      this.minMaxTranslate = {
        x: {
          min: -(this.zoomMarge.x),
          max: -(this.width - this.$device.width - this.zoomMarge.x)
        },
        y: {
          min: -(this.zoomMarge.y),
          max: -(this.height - this.$device.height - this.zoomMarge.y)
        },
      }
    },

    onLoadedMetaData (event) {
      this.content.width = event.target.videoWidth
      this.content.height = event.target.videoHeight
      this.onResize()


      this.currentTransform.x = this.transform.x = ( this.$device.width - this.width ) * .5
      this.currentTransform.y = this.transform.y = ( this.$device.height - this.height ) * .5
    }
  }
}
</script>