import { reactive, ref } from 'vue'

export default {
  install (app, options) {
    let device = {}

    device.width = ref(0)
    device.height = ref(0)
    device.scroll = reactive({ left: ref(0), top: ref(0) })
    device.pixelRatio = ref(window.devicePixelRatio)
    device.orientation = ref('landscape')

    device.isMobile = ref(false)
    device.isTablet = ref(false)
    device.isDesktop = ref(false)
    device.isTouch = ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)
    device.isIpad = navigator.userAgent.match(/.*(iPad).*/)
    device.isIphone = navigator.userAgent.match(/.*(iPhone).*/)
    device.isAndroid = navigator.userAgent.match(/.*(Android).*/)
    device.isFirefox = navigator.userAgent.match(/.*((f|F)irefox).*/)
    device.isChrome = navigator.userAgent.match(/.*(Chrome).*/)
    device.isSafari = navigator.userAgent.match(/.*(Safari).*/) && !device.isChrome
    /* eslint-disable */
    device.isTrident = navigator.userAgent.match(/Trident.*rv\:11\./)
    device.isEdge = navigator.userAgent.match(/.*(Edge).*/)
    device.isMSIE = navigator.userAgent.match(/.*(MSIE ).*/)

    device.pointer = !!window.navigator.pointerEnabled
    device.msPointer = !!window.navigator.msPointerEnabled
    device.pointerdown = device.isTouch ? 'touchstart' : 'pointerdown'
    device.pointerup = device.isTouch ? 'touchend' : 'pointerup'
    device.pointermove = device.isTouch ? 'touchmove' : 'pointermove'
    device.pointerenter = device.isTouch ? 'touchstart' : 'pointerenter'
    device.pointerleave = device.isTouch ? 'touchend' : 'pointerleave'
    device.pointerover = device.isTouch ? 'touchstart' : 'pointerover'
    device.pointerout = device.isTouch ? 'touchend' : 'pointerout'
    device.click = 'click'

    device._onResize = () => {
      device.width.value = window.innerWidth
      device.height.value = window.innerHeight
      device.isMobile.value = device.width.value <= 780
      device.isTablet.value = device.width.value <= 1024
      device.isDesktop.value = device.width.value > 1230
      device.orientation.value = window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape'
      device.pixelRatio.value = window.devicePixelRatio
    }

    device._onScroll = () => {
      const doc = document.documentElement

      device.scroll.left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
      device.scroll.top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
    }

    device.getPointerPosition = event => {
      let clientY = event.clientY
      let clientX = event.clientX

      if (event.touches) {
        clientY = event.touches[0].pageY
        clientX = event.touches[0].pageX
      }

      return {
        x: clientX,
        y: clientY
      }
    }

    app.config.globalProperties.$device = reactive(device)
  }
}
