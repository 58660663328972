export default class Pool {

	static assign( Module, reset ) {
		Module.pool = new Pool( Module )
		Module.pool.reset = reset

		Module.get = function () {
			const module = Module.pool.get()
			if ( module && Module.pool.reset ) Module.pool.reset( module )

			return module
		}

		Module.release = function () {
			Module.pool.release( ...arguments )
		}

		Module.prototype.release = function () {
			Module.pool.release( this )
			return this
		}
	}

	constructor( prototype ) {
		this.prototype = prototype

		this.items = []
		this.availables = []
	}

	clear() {
		this.items = []
		this.availables = []
	}

	release() {
		for ( let i = 0; i < arguments.length; i++ ) {
			const item = arguments[ i ]

			if ( ! item.isFromPool ) throw new Error( 'The item is not from the pool' )
			this.availables.push( item )
		}
	}

	get() {
		if ( this.items.length > this.maxLength ) return

		if ( ! this.availables.length ) {
			// eslint-disable-next-line new-cap
			const item = new this.prototype()
			item.isFromPool = true
			this.items.push( item )

			return item
		} else return this.availables.pop()
	}

}
