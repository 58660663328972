import { DirectionalLight } from 'three'

import GameObject from '../../abstractions/GameObject'

export default class MainLight extends GameObject {

	init( props ) {
		this.base = new DirectionalLight( 0xFFFFFF, .2 )
		this.base.position.z = 0
		this.base.position.y = 10
		this.base.position.x = 2
		this.base.castShadow = true
		this.base.shadow.camera.near = .1
		this.base.shadow.camera.far = 50
		this.base.shadow.camera.top = 10
		this.base.shadow.camera.right = 10
		this.base.shadow.camera.bottom = -10
		this.base.shadow.camera.left = -10
		this.base.shadow.mapSize.width = 2048
		this.base.shadow.mapSize.height = 2048

		this.base.shadow.camera.updateProjectionMatrix()
	}

	destroy() {
		this.base.shadow.map.dispose()
	}

	update( dt ) {
		super.update( dt )
	}

	// setTarget( bubblePos, mouthPos, instant ) {



	// 	if ( instant ) this.pos.current.copy( this.pos.target );

	// }

}

