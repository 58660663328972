import { gameConfig } from "../config/gameConfig"

let lane = [1, 2, 3]
let lastLane = null
let previousLane = null
let numberCoins = { min: gameConfig.minCoinPerSpawn, max: gameConfig.maxCoinPerSpawn }
const laneLenght = 3
const zSpace = 10
var valueSwapnTimer = null
var last = 0; // timestamp of the last render() call
let probability = Math.random()
let lastByLane = {
  1: null,
  2: null,
  3: null,
}
// if (Random.value > 0.5) //%50 percent chance
// {//code here
// }

const obstacles = ["corona","twitter", "yellow","scandal"]

function getNumber() {
  var min = 1,
    max = 4,
    random;

  do {
    random = Math.floor(Math.random() * (max - min)) + min;
  } while (random === getNumber.last);
  getNumber.last = random;
  return random;
}

function getSpawnDatas() {
  if (lastLane) lane.unshift(lastLane)
  previousLane = lastLane
  var randCoinsNumber = null
  var valueSwapnTimer = (Math.floor(Math.random() * 10) + 1) / 10;
  var obstacleName = null
  // console.log('VALUE SPAWN', valueSwapnTimer)
  const randomIndex = Math.floor(Math.random() * lane.length)
  const randomLane = getNumber()
  lastLane = randomLane
  lane.splice(randomIndex, 1)
  probability = Math.random()
  let type = null
  if (probability < gameConfig.obstacleProbapility) //%30 percent chance (1 - 0.7 is 0.3)
  { //code here
    type = 'obstacle'
    obstacleName = obstacles[Math.floor(Math.random() * obstacles.length)];
  } else {
    type = 'coin'
    randCoinsNumber = Math.floor(Math.random() * (gameConfig.maxCoinPerSpawn - gameConfig.minCoinPerSpawn + 1) + gameConfig.minCoinPerSpawn);
  }


  return {
    type,
    coin: randCoinsNumber,
    timeToSpawn: valueSwapnTimer / gameConfig.speedSpawner,
    lane: randomLane,
    previousLane,
    lastLane,
    obstacleName,
  }
}

function update() {

}

export {
  getSpawnDatas,
  lastByLane,
  zSpace
}