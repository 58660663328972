import AppStore from "../../store/AppStore"

let touchstartX = 0
let touchendX = 0

function init() {
  addEventListener('touchstart', onTouchStart)
  addEventListener('touchend', onTouchEnd)

  function handleGesture() {
    if (touchendX < touchstartX) return 'left'
    if (touchendX > touchstartX) return 'right'
  }

  function onTouchStart(e) {
    touchstartX = e.changedTouches[0].screenX || e.touches[0].clientX
  }

  function onTouchEnd(e) {
    touchendX = e.changedTouches[0].screenX || e.touches[0].clientX
    AppStore.swipe.dispatch({
      code: handleGesture()
    })
  }
}


export default { init }


