import Signal from '../utils/store/Signal'

import w from '../utils/store/writeable'

const GameStore = {
	started: w(false),
	paused: w(true),
	playerReady: w(false),
	ready: w(false),
	reset: new Signal(),
	endGame: w(false),
	characterDatas: w({
		1: {
			name: w(""),
			question: w(1),
			score: w(0)
		},
		2: {
			name: w(""),
			question: w(1),
			score: w(0)
		},
		3: {
			name: w(""),
			question: w(1),
			score: w(0)
		},
	}),
	character: w(1),
	score: w(0),
	canAnswer: w(false),
	runMode: w(true),
	quizzMode: w(false),
	currentView: w(null),
	currentStep: w(null),
	goToCurrentStep: new Signal(),
	nextView: w(null),
}

export default GameStore
