
let animations = {}

function setName(anim, name) {
	anim.name = name
	return anim.name
}

function setAnimations(assets) {
	animations = {
		// player_running: {
		// 	animation: assets.running.animations[0],
		// 	loop: false,
		// 	clampWhenFinished: false,
		// 	name: setName(assets.running.animations[0], 'player_running'),
		// 	timeScale: 0.85
		// }
	}
	return Promise.resolve()
}

function getAnimations() {
	return animations
}

export default {
	setAnimations,
	getAnimations
}
