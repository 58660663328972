import { ShaderChunk } from 'three'

import fxaa from './fxaa.glsl'

let added = false
export default function addChunks() {
	if ( added ) return
	Object.assign( ShaderChunk, {
		fxaa,
	} )
	added = true
}

