
import { Object3D, Vector3, Mesh, MeshBasicMaterial, SphereBufferGeometry, MeshPhongMaterial, AnimationMixer, AnimationClip, LoopOnce, MeshStandardMaterial, Vector2, Euler } from 'three'

import GameObject from '../../abstractions/GameObject'
import { cloneGLTF } from '../../utils/cloneGLTF'

import AppStore from '../../../store/AppStore'
import GameStore from '../../../store/GameStore'

import Files from '../../../managers/assetsManager/Files'
// import SoundManager from '../../../managers/soundManager/SoundManager'

import { gsap, Power1 } from 'gsap/all'
import { gameConfig } from '../../config/gameConfig'
import { Box3 } from 'three'
import { emitter } from '@/utils/emitter'
import { events } from '../../config/events'
import { FrontSide } from 'three'
import DebugStore from '../../../store/DebugStore'
import gui from '../../utils/gui'
import HitParticles from './HitParticles'
import GrimacinParticles from './GrimacinParticles'
import SmilingParticles from './SmilingParticles'


export default class Character extends GameObject {

	init(props) {
		const gameAssets = Files.get('game')
		this.name = props.model || "character2"
		// console.log('CHARACTER', gameAssets[this.name])
		this.character = cloneGLTF(gameAssets[this.name])
		this.model = this.character.scene

		const colorParsChunk = [
			'vec3 displace(vec3 v) {',
			'float dist = distance(v.z, cameraPosition.z);',
			'if( dist < 0.0) { dist = 0.0; }',
			'float addY = dist * dist;',
			`return vec3(v.x, v.y - addY * (1. * ${gameConfig.bendY.toString()}), v.z);`,
			'}',
			'#include <common>'
		].join('\n');

		const instanceColorChunk = [
			'#include <worldpos_vertex>',
			'transformed = worldPosition.xyz;',
			'transformed = displace(transformed);',
			'gl_Position = projectionMatrix * viewMatrix * vec4(transformed, 1.);'
		].join('\n');

		this.mesh = this.model.children[0].children[1]
		this.mesh.castShadow = true
		this.mesh.frustumCulled = false
		this.material = this.mesh.material
		this.material.roughness = .4
		this.material.clearcoat = 0
		this.material.metalness = 0
		this.material.clearcotRoughness = 0
		this.material.transparent = true
		this.material.side = FrontSide

		if (DebugStore.useGui.current) {
			let materialCharacter = gui.folder('Character Material ' + this.name)
			var params = {
				roughness: 0.57,
				clearcoat: 0,
				clearcotRoughness: 0,
				metalness: 0,
			}

			materialCharacter.add(params, 'roughness', 0, 1, 0.01).name('roughness')
				.listen()
				.onChange(() => {
					this.material.roughness = params.roughness
				});
			materialCharacter.add(params, 'clearcoat', 0, 1, 0.01).name('clearcoat')
				.listen()
				.onChange(() => {
					this.material.clearcoat = params.clearcoat
				});

			materialCharacter.add(params, 'clearcotRoughness', 0, 1, 0.01).name('clearcotRoughness')
				.listen()
				.onChange(() => {
					this.material.clearcotRoughness = params.clearcotRoughness
				});

			materialCharacter.add(params, 'metalness', 0, 1, 0.01).name('metalness')
				.listen()
				.onChange(() => {
					this.material.metalness = params.metalness
				});
		}

		this.material.onBeforeCompile = function (shader) {

			shader.vertexShader = shader.vertexShader
				.replace('#include <common>', colorParsChunk)
				.replace('#include <worldpos_vertex>', instanceColorChunk);

			shader.fragmentShader = shader.fragmentShader
			// .replace('#include <common>', fragmentParsChunk)
			// .replace('vec4 diffuseColor = vec4( diffuse, opacity );', colorChunk);

			//console.log( shader.uniforms );
			//console.log( shader.vertexShader );
			//console.log( shader.fragmentShader );
		};

		// this.mesh.material.dispose()
		this.controls = props.controls
		this.mixer = new AnimationMixer(this.character.scene)
		this.setAnimation("Armature.001|mixamo.com|Layer0", 0, 0)
		this.base = this.model
		this.base.frustumCulled = false
		this.canAnswer = false
		this.hasAnswer = false
		this.hasChoosen = false
		this.endGame = false

		this.gsapEnter = null

		this.lastView = null
		this.lastStep = null
		this.lane = 1;
		this.position = props.position ? props.position : new Vector3()
		this.scale = props.scale ? props.scale : new Vector3(0.016, 0.016, 0.016)
		this.base.scale.set(this.scale.x, this.scale.y, this.scale.z)
		this.base.position.set(this.position.x, this.position.y, this.position.z)
		this.id = props.id

		this.xToMove = 0

		this.last = 0

		this.isEnter = false
		this.canMove = false

		this.addBody()
		this.initParticlesDizzy()
		this.initGrimacingParticles()
		this.initSmilingParticles()
		this.onViewChange(AppStore.currentView.get())
		this.onStepChange(AppStore.currentStep.get())
		this.bind()

	}

	goToLeft() {
		if (this.lane == 0) return
		this.xToMove += gameConfig.characterToTranslate
		gsap.to(this.base.position, { x: this.xToMove, duration: .15, ease: Power1.easeOut })
		this.lane--
		this.chooseAnswer('left')
		this.resetTimeToAnswer()

	}



	goToRight() {
		if (this.lane === 2) return
		this.xToMove -= gameConfig.characterToTranslate
		gsap.to(this.base.position, { x: this.xToMove, duration: .15, ease: Power1.easeOut })
		this.lane++
		this.chooseAnswer('right')
		this.resetTimeToAnswer()
	}

	hitAnimation() {
		var tl = gsap.timeline({ repeat: 1, });
		tl.to(this.material, { opacity: 0, duration: .02 });
		tl.to(this.material, { opacity: 1, duration: .02, delay: .1 });

		tl.play()

		this.particlesDizzy.playDizzy()

	}

	goTocenter({ delay, duration }) {
		this.lane = 1
		this.xToMove = 0
		return gsap.to(this.base.position, { x: this.xToMove, duration: duration || .15, ease: Power1.easeOut, delay: delay || 0 })
	}


	resetTimeToAnswer() {
		if (!this.canAnswer && this.hasAnswer && this.hasChoosen) return
		this.last = Date.now()
		emitter.emit(events.ANSWER_STOP)
	}

	chooseAnswer(direction) {
		if (this.canAnswer && !this.hasAnswer) {
			if (this.lane === 0 || this.lane === 2) {
				this.hasChoosen = true
				emitter.emit(events.ANSWER_START, { direction })
			}
			if (this.lane === 1) {
				this.hasChoosen = false
				emitter.emit(events.ANSWER_START, { direction: '' })
			}

		}

	}

	bind() {
		// this.onCollide = this.onCollide.bind(this)
		// this.body.addEventListener('collide', this.onCollide)
		this.onViewChange = this.onViewChange.bind(this)
		this.onStepChange = this.onStepChange.bind(this)
		this.setCanAnswer = this.setCanAnswer.bind(this)

		this.handleEndGame = this.handleEndGame.bind(this)

		AppStore.currentView.subscribe(this.onViewChange)
		AppStore.currentStep.subscribe(this.onStepChange)

		GameStore.canAnswer.subscribe(this.setCanAnswer)

	}

	unbind() {
		AppStore.currentView.unsubscribe(this.onViewChange)
		AppStore.currentStep.unsubscribe(this.onStepChange)

		GameStore.canAnswer.unsubscribe(this.setCanAnswer, this)

	}

	setCanAnswer() {
		// console.log(GameStore.character.current, this.id)
		if (!GameStore.canAnswer.current) return
		if (this.id !== GameStore.character.current) return
		// console.log('CAN ANSWER')
		this.canMove = true
		this.canAnswer = true
		this.last = Date.now()
	}

	onStepChange(e) {
		this.lastStep = e
	}

	onViewChange(e) {
		this.lastView = e
	}

	onPositionChange(key) {
		if (!this.canMove) return
		switch (key.code) {
			case 'KeyA':
			case 'KeyQ':
			case 'ArrowLeft':
				this.goToLeft()
				break;
			case 'KeyD':
			case 'ArrowRight':
				this.goToRight()
				break;
		}
	}

	initParticlesDizzy() {
		this.particlesDizzy = new HitParticles({ character: this })
	}

	initGrimacingParticles() {
		this.particlesGrimacing = new GrimacinParticles({ character: this })
	}

	initSmilingParticles() {
		this.particlesSmiling = new SmilingParticles({ character: this })
	}

	resetQuizzMode() {
		GameStore.canAnswer.set(false)
		this.canAnswer = false
		this.hasAnswer = false
		this.hasChoosen = false
	}

	onEnterAnimation({ duration, delay }) {
		this.gsapEnter = gsap.to(this.base.position, {
			x: this.position.x, y: this.position.y, z: -10, duration: duration || 4, delay: delay || 1, onComplete: (() => {
				this.isEnter = true
				GameStore.playerReady.set(true)
				this.canMove = GameStore.playerReady.current
			})
		})

		return this.gsapEnter
	}

	hide() {
		GameStore.playerReady.set(false)
		return gsap.to(this.base.position, {
			x: this.xToMove, y: this.position.y, z: -20, duration: 2, delay: 1
		})
	}

	mod(n, m) {
		return ((n % m) + m) % m
	}

	addBody() {

		this.body = new Box3(new Vector3(), new Vector3())
		this.mesh.geometry.computeBoundingBox()

		this.body.name = "character"
	}

	destroy() {
		this.unbind()
		this.mesh.geometry.dispose()
		this.model.traverse(child => {
			if (child.isMesh) {
				child.skeleton.boneTexture.dispose()
			}
		})

		if (this.shadow) {
			this.shadow.destroy()
		}

		if (this.particlesDizzy) {
			this.particlesDizzy.destroy()
		}

		if (this.particlesGrimacing) {
			this.particlesGrimacing.destroy()
		}

		if (this.particlesSmiling) {
			this.particlesSmiling.destroy()
		}

		super.destroy()
	}

	updateCameraPos() {

	}
	handleEndGame() {
		this.canMove = false
		this.endGame = true
	}

	setAnimation(clipName, startAt = 0, fadeIn = .2) {
		if (this.mixer !== undefined) {
			const action = this.mixer.clipAction(this.character.animations[0])
			this.mixer.stopAllAction()
			action.timeScale = this.character.animations[0].timeScale || 1
			action.time = (startAt)
			action.fadeIn(fadeIn)
			action.play()
			return action._clip.duration
		}
	}

	checkCollistion(allBoxes) {
		allBoxes.forEach(box => {
			const collision = this.body.intersectsBox(box)
			if (collision && !box.collision) {
				box.collision = true
			}
		});
	}

	update(dt) {
		super.update(dt)


		if (this.mixer !== undefined) this.mixer.update(0.016)

		if (this.endGame) this.base.position.z += .25

		this.body.copy(this.mesh.geometry.boundingBox).applyMatrix4(this.model.matrixWorld);

	}

}

