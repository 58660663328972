<template>
  <div class="popin u-wrapper-panel u-row u-center u-middle">
    <div class="u-w5of12 u-w10of12--sm u-relative">
      <div class="popin__wrapper u-relative u-radius u-overflow-hidden">
        <div class="popin__content popin__fader u-relative">
          <div class="t-h2 u-red u-relative">
            Abandonner la course à l’Elysée ?
          </div>
          <div class="u-radius u-bg-white u-pad-md u-marg-t-md">
            <div class="t-text u-red u-relative">
              L’ensemble de votre progression sera perdue et ne sera pas ajouté à vos statistiques<br />(Et votre budget de campagne ne sera pas remboursé)
            </div>
            <div class="u-row u-marg-t-sm u-evenly">
              <app-button class="u-w8of12--sm" @click="$emit('close')" :isGhost="true">
                Jamais!
              </app-button>
              <app-button class="u-w8of12--sm u-marg-t-sm--sm" :to="{name: 'Homepage'}">
                Abandonner
              </app-button>
            </div>
          </div>
        </div>
      </div>
      <div class="popin__picto popin__fader u-round u-box-1by1 u-bg-red">
        <div class="popin__picto__wrapper u-wrapper-panel">
          <svg class="u-fill-white u-full-width u-full-height" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#exclamation"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'

export default {
  components: {
    AppPopin
  }
}
</script>