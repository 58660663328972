<template>
  <div class="question">
    <div class="u-row u-center">
      <div class="question__wrapper u-w6of12 u-w10of12--sm u-radius u-bg-white u-relative">
        <!-- cartel -->
        <div class="question__cartel question__scale u-radius u-row u-center u-middle u-overflow-hidden" :style="{backgroundColor: candidate.color}">
          <div class="u-w3of12 u-full-height u-relative">
            <app-image v-if="candidate.picto" class="u-wrapper-panel u-fit-cover" :id="candidate.picto.id" :width="100" />
          </div>
          <div class="t-h3 u-w9of12 u-white u-left u-pad-l-xs">
            {{ candidate.shortName }} veut
          </div>
        </div>

        <timer class="question__timer question__scale" :total="total" :elapsed="time" />
        <div class="u-pad-md u-pad-t-sm u-center u-blue t-h3">
          {{ data.title }}
        </div>
      </div>
    </div>
    <div class="question__opacity u-row u-center u-marg-t-md">
      <div class="u-w6of12 u-w10of12--sm u-row u-between">
        <app-button-quizz
          v-for="(answer, i) in data.answers"
          :isTrue="answer.value"
          :isLeft="isLeft(i)"
          class="u-w4of12 u-w5of12--sm"
          :isFakeSelected="isLeft(i) ? isOnLeft : isOnRight"
          @selected="onAnswer({value: answer.value, direction: isLeft(i) ? 'left' : 'right'})"
        >
          {{ answer.label }}
        </app-button-quizz>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from '@/components/common/Timer'
import AppButtonQuizz from '@/components/common/AppButtonQuizz'

import { events } from '@/webGL/canvas/config/events'

import { timer } from '@/assets/data'

export default {
  components: {
    Timer,
    AppButtonQuizz
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    candidate: {
      type: Object,
      default: () => {return {}}
    },
  },

  data () {
    return {
      total: timer.total,
      time: 0,
      isOnLeft: false,
      isOnRight: false
    }
  },

  mounted () {
    this.$hub.on(events.ANSWER_START, this.onDirection)

    this.interval = setInterval(() => {
      this.time++
      if (this.time > this.total) {
        this.$emit('answer', {value: false, direction: ''})
      }
    }, 1000)
  },

  methods: {
    isLeft (i) {
      return i % 2 === 0
    },

    onAnswer (args) {
      setTimeout(() => {
        this.$emit('answer', args)
      }, 300)
    },

    onDirection ({ direction }) {
      this.isOnLeft = false
      this.isOnRight = false

      if (direction === 'left') {
        this.isOnLeft = true
      } 
      else if (direction === 'right') {
        this.isOnRight = true
      }
    }
  },

  beforeUnmount () {
    clearInterval(this.interval)
    this.$hub.off(events.ANSWER_START, this.onDirection)
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.question
  position absolute
  top 100px
  left 0
  width 100%
  
  &__wrapper
    content ''
    position aboslute
    top 0
    left 0
    width 100%
    height 100%
    background-color: $colors.white
    transform scale(1, 1)
    opacity 1
    transition transform .7s ease-in-out-quad, opacity 1s 1s
    
  &.custom-enter-active &__wrapper
    transition transform .8s ease-in-out-quad, opacity .5s .2s

  &.custom-enter-from &__wrapper
    transform scale(0, 0)

  &.custom-leave-to &__wrapper
    transform scale(0, 0)
    transition transform 1s ease-in-out-quad, opacity .5s

  &__scale
    opacity 1
    transform scale(1, 1)
    transition transform 1s ease-in-out-quad

  &.custom-enter-active &__scale
    opacity 1
    transition transform .7s .5s ease-in-out-quad, opacity .5s .2s

  &.custom-enter-from &__scale
    transform scale(0, 0)
    opacity 0

  &.custom-leave-to &__scale
    transform scale(0, 0)
    opacity 0

  &__opacity
    opacity 1
    transition opacity .5s 1.2s linear

  &.custom-enter-from &__opacity
    opacity 0

  &.custom-leave-to &__opacity
    transition opacity .5s linear
    opacity 0

  &__timer
    position absolute !important
    top -45px
    right 35px

    +mq($until: 'tablet')
      top -25px
      right 20px

  &__cartel
    width 360px
    height 90px
    max-width 60%
    margin-top -45px
    
    +mq($until: 'tablet')
      height 50px
      margin-top -25px
</style>