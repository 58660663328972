import Signal from './Signal'

/**
 * Inherits from [Signal](#Signal) - See its API for `subscribe` / `unsubscribe` methods
 *
 * :warning: `dispatch()` method from Signal is removed and replaced by a `set()` method
 * @param {*} initialValue Initial value
 * @augments Signal
 * @return {Readable} Readable instance
 */
export class Readable extends Signal {

	constructor( initialValue ) {
		super()
		this.current = initialValue
	}

	get() {
		return this.current
	}

}

function readable( initialValue ) {
	return new Readable( initialValue )
}

export default readable
