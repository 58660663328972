<template>
  <div class="timer u-box-1by1">
    <div class="u-wrapper-panel u-bg-white u-round u-row u-middle u-center">
      <div class="t-h3 u-red">
        {{ total - elapsed }}
      </div>
    </div>
    <svg class="timer__svg-base u-wrapper-panel u-stroke-red" preserveAspectRatio="xMidYMid meet">
      <use xlink:href="#circle"/>
    </svg>
    <svg
      class="timer__svg-over u-wrapper-panel u-stroke-red"
      preserveAspectRatio="xMidYMid meet"
      :style="{'stroke-dashoffset': 227 - 227 * (elapsed / total)}"
    >
      <use xlink:href="#circle"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    elapsed: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'

.timer
  width 85px
  
  +mq($until: 'tablet')
    width 50px

  &__svg-base
    opacity .3

  &__svg-over
    stroke-dasharray 227
    transition stroke-dashoffset .7s ease-in-out-quad
    transform rotate(-90deg)
</style>